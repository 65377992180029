
import {useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Routes, Route, useNavigate, Link } from 'react-router-dom'
import './Sections.css'
import DeleteModal from '../deleteModal/DeleteModal';
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";

import Grid from '../grid/Grid'
import { type } from '@testing-library/user-event/dist/type';
import BaseInput from '../baseInput/BaseInput'
import Filters from '../filters/Filters';
import { updateItem } from '../../../redux/actions/baseDataActions';


function Sections(props) {

    const { currentItem, currentLog }  = useSelector(state => state.base)
    const feildsData = currentItem?.feildsData
    const [params, setParams] = useState(null);
    const dispatch = useDispatch();

     const extractValue = (field, value) => {
      if(field.type == 'multiselect' && value) {
        return value.map(part => typeof part == 'object' && part.value ? part.value : part).join(',')
      }
      if (field.type == 'treeview' && value) {
        
        return JSON.stringify(value)
      }

      return value === undefined ? '' : value;
    }

    const setTabSelected = props.setTabSelected;

    const Blur = (e, value) => {
        if (value == e.value) {
            return
        }
      var feildsData = {};
      feildsData[e.fieldName] = extractValue(e, value);

      dispatch(
        updateItem({
          model: props.model,
          id: props.Id,
          data: feildsData,
          isParent: true,
          isInline: true,
          field : e.field
        })
      );
    }

    const UnChange = (e, value, sendToServer = true) => {
      if (!sendToServer) {
        return;
        }
        
        if (['text', 'email', 'tel', 'number', 'textarea', 'editor', 'float'].includes(e.type)) {
            return;
        }
      var feildsData = {};
      feildsData[e.fieldName] = extractValue(e, value);

      if(e.type == 'map' && typeof value === 'object') {
        feildsData[e.fieldName] = value[e.fieldName];
        feildsData['lat'] = value['lat'];
        feildsData['lng'] = value['lng'];
      }

      dispatch(
        updateItem({
          model: props.model,
          id: props.Id,
          data: feildsData,
          isParent: true,
          isInline: true,
          field : e.field
        })
      );

    };


    const getTitle = (field, value) => {
   
        if(field.type === 'select') {
            return field.options.find(opt => opt.value == value)?.name
        }
        if (field.type == "multiselect") {
            if (value && value[0] && typeof value[0] == 'object') {
                return value.map(part => typeof part == 'object' && part.name ? part.name : part).join(', ')
            }
        
            return field.options.filter(opt => value.includes(opt.value)).map(v => v.name).join(', ')
        }
        if(field.type === 'autocomplete') {
            const data = field.options?.find(opt => opt.id == value)
            if (!data) {
                return ''
            }
            if (field.searchType) {
                return (<a  href={'/' + field.searchType + '/' + data.id}>{data.label} </a>)
            }
            return data.label
        }
        if(field.type == 'checkbox') {          
            return value ? 'כן' : 'לא'
        }
        if(field.type == 'color') {
            return value ? <span className={'k-color-preview-mask'} style={{background: value, width: '50px', height: '20px', display: 'block'}}></span> : ''
        }
        if(field.type == 'file') {
            if(value && !value.name && (value.includes('jpg') || value.includes('png') || value.includes('jpeg'))) {
                return ( <img style={{maxWidth:'100%',maxHeight: '70px'}} src={process.env.REACT_APP_API + '/' + value}></img> )
            }
            if(value) {
                return (
                    <a  href={process.env.REACT_APP_API + '/' + value} download>
                        הורדת הקובץ 
                    </a>
                )
            }
        }
        if(field.type == 'editor') {
            return value ?
                <Tooltip openDelay={1} position="auto" tooltipStyle={{ maxWidth: '400px', direction: 'rtl'}} content={(props) => <div dangerouslySetInnerHTML={{ __html: value }}></div>}>
                    <div title={value} className='editor_value' dangerouslySetInnerHTML={{ __html: value }} ></div>
                    </Tooltip>: ''
        }
        if(field.type == 'textarea' && value && value.length > 200) {
            return  <Tooltip openDelay={100} position="auto" tooltipStyle={{ maxWidth: '400px', whiteSpace: 'pre-line', border: '1px solid black', direction: 'rtl', textAlign : 'justify'}}>
                <div title={value} style={{'whiteSpace': 'pre-line'}}> {value} </div>
            </Tooltip>
        }
        

        if(field.type == 'date' && value) {
            const date = new Date(value);
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return  dd + '/' + mm + '/' + yyyy + (date.getHours() ? (' ' + date.getHours()+':' + date.getMinutes())  :'');
        }

        if (field.type == 'treeview' && value) {
           
            return (
                <BaseInput data={{
                    ...field,
                    label: '',
                    isEdit: false,
                    items: field.items
                        .filter(item => !!feildsData[field.fieldName][item.Id])
                        .map(item => !item.items ? item : { ...item, items: item.items.filter(child => !!feildsData[field.fieldName][child.Id]) }),
                    value: feildsData[field.fieldName]
                }} />
                )
        }

        if(field.type == 'raw') {
            return <div dangerouslySetInnerHTML={{__html: value}}></div>
        }

        return value

    }

    const openDialog = (id) => {
        props.openEditDialog(id)
    }

    const Filter = (params) => {
   
    setParams(params)
    }
    

    const renderContent = (section, props, i, IsEdit = true, modelTab = '') => {
        switch (section.type) {

            case "fields":
                return (<div className={'section-feilds ' + section?.floatClass || '' } >
                        <h3 className='section_title_name'>
                            {section.title} 
                            {/*IsEdit  ? <Button fillMode="flat" className='btn-edit' onClick={() => openDialog(i)} themeColor={'auto'}  icon={'edit'} title="ערוך"/>
                            :   ''*/}
                        </h3>
                        
                    {(section?.fields ? section.fields.map((field, i) => (
                        <div className={'feild'} key={'feild' + i}>
                            <div className={'title' +  (section?.floatClass && section?.floatClass == 'width-100' ? ' col-2' : ' col-3')}>
                                <div style={{ width: '90%' }}>{field.label}</div>
                                {field.sub && <a style={{ float: 'left', marginLeft: '10px', marginTop: '-20px'}} target='_blank' href={field.sub.link}><img src={`data:image/svg+xml;base64,${btoa(field.sub.img)}`} /></a> }
                            </div>
                             
                            {field.readOnly
                                ?  <div className='section-feilds-value col-9'> {getTitle(field, feildsData[field.fieldName] || field.value)} </div>
                                : <div className='section-feilds-value col-9 section-input' >
                                    <BaseInput  key={field.fieldName} onChange={UnChange} onBlur={Blur}
                                       feildsData={feildsData}
                                        data={{
                                            ...field,
                                            model: props.model,
                                            placeholder: '',
                                            label: '',
                                            isView: true,
                                            value: feildsData ? feildsData[field.fieldName] : undefined
                                        }} />
                                    </div>
                            }
                            </div>
                         
                         /*<div className='feild' key={'feild' + i}>

                                <div className='title col-3'><div style={{width: '90%'}}>{filed.label }</div></div>
                                <div className='section-feilds-value col-9'> {getTitle(filed, feildsData[filed.fieldName] || filed.value)} </div>
                            </div>*/
                        )) : null)}
                            {section.children && section.children.length > 0 ? section.map(sec => {
                                return (
                                    <>
                                    <h3 className='section_title_name'>
                                        {sec.title} 
                                        {IsEdit  ? <Button fillMode="flat" className='btn-edit' onClick={() => openDialog(i)} themeColor={'auto'}  icon={'edit'} title="ערוך"/>
                                        :   ''}
                                    </h3>
                                    {(sec?.fields ? sec.fields.map((filed, i) => (
                                    <div className='feild' key={'feild' + i}>
        
                                        <div className='title col-3'><div style={{width: '90%'}}>{filed.label }</div></div>
                                        <div className='section-feilds-value col-9'> {getTitle(filed, feildsData[filed.fieldName] || filed.value)} </div>
                                    </div>
                                )) : null)}
                                </>
                                
                              
                                )
                            }
                            ) : null}
                        </div>);
            case "table":  
                return (<div className='section' key={i}>
                    <h3>{section.title} </h3>
                    <Filters settings={section.LOOKUP} model={modelTab} defParams={params} FilterChanged={Filter} />
                    <Grid title={section.title} maxHeight="260px"
                        section={section}
                        model={modelTab}
                        moreActions={section.moreActions}
                        actions={section.actions}
                        columns={section.columns}
                        sortBy={props.data?.sort}
                        pageable={section?.pageable === undefined ? true : !!section.pageable}
                        setTabSelected={setTabSelected}
                        isParent={false} />
                </div>)

            default:
                return <h1>No project match</h1>
        }
    }

    return (
        <div>
            <div className={props.data.isNotFlex ? '' : 'sections' }>
                {feildsData && props.data?.sections.map((section, i) => (
                    <>
                        {renderContent(section, props, i, section.IsEdit, section.url?.replace('{id}', props.Id))}
                    </>
                ))}
            </div>
        </div>
    )
}

export default Sections