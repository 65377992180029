import React from "react";
import { Popup } from "@progress/kendo-react-popup";
import "./SidePopup.css";
import { Slide } from "@progress/kendo-react-animation";
 import Notes from "../../notes/Notes"
const SidePopup = (props) => {



  return (
      <div className="side">
        <div className="closePopup" onClick={props.onClosePopup}>
          <span className="k-icon k-i-close"></span>
        </div>
      <Notes model={props.model} sendWhatsapp={true} />
      </div>
    )
  ;
};

export default SidePopup;
