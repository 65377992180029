import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import  ExtraAction  from '../layout/baseInput/ExtraAction'
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import Title from "../layout/title/Title";
import {
  lookup,
  getView,
  updateItem,
  updateFields,
  create,
  updateCurrentItemFileds,
} from "../../redux/actions/baseDataActions";
import { Loader } from "@progress/kendo-react-indicators";
import "./EditForm.css";
import { Button } from "@progress/kendo-react-buttons";
import BaseInput from "../layout/baseInput/BaseInput";

const EditForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navige = useNavigate();
  const params = useParams();
  const currentModel = props.model;
  const base = useSelector((state) => state.base);
  const { loading } = useSelector((state) => state.async);

    var currentItem = props.isParent ?  base.currentItem : base.currentItemChild 
    const id =  props.id ? props.id : (props.mode == 'create' ? 0 : params.id)

    useEffect(() => {
        if(!currentItem || currentItem.model != props.model) {
           if(props.isParent) {
             dispatch(lookup({ name: props.model}))
              
            dispatch(getView({
                model: props.model,
                id: id,
                isParent : props.isParent}))
            } 
           
        }
    }, [])
  
    const [feildsData, setFeildsData] = useState({...currentItem?.editFeildsData});
  
  var section = props.mode == "create" ? 0 : props.section;
  if (!section && params.section) {
    section = params.section;
  }

  var tab = props.mode == "create" ? 0 : props.tab;
  if (!tab) {
    tab = params.tab || 0;
  }

  const fields = currentItem && currentItem?.tabs
    ? currentItem?.tabs[tab]?.sections[section]?.fields
    : (currentItem?.fields ? currentItem.fields : []);

  const extraActions = currentItem?.tabs ? currentItem?.tabs[tab]?.sections[section]?.extraActions : []

  const errorValidation = currentItem?.errorValidation;

  const validaionMsg = (field) => {
    if (!errorValidation) {
      return null;
    }
}

    const Cancel = () => {
        if(props.close) {
            props.close()
        } 
        else { 
            navige(-1)
        }
        
    }

  const UnChange = (e, value, sendToServer = true) => {
    if (value === undefined) {
      return
    }
    
    feildsData[e.fieldName] = value;
    setFeildsData(feildsData)
    
    const field = fields.find(f => f.fieldName == e.fieldName)
    if (field.onchange) {
      onChangeFunc(field.onchange, value)
    }

    if (!sendToServer) {
      return
    }
    
    if(!['textarea', 'editor', 'file', 'text', 'tel', 'email', 'autocomplete'].includes(field.type)) {
      dispatch(updateFields({ data: feildsData, isParent: props.isParent }));
    }

    
  };

  const onChangeFunc = (onchange, value) =>  {
    switch (onchange.type) {
      case 'readonly-field':
        Object.keys(onchange.options).forEach(key => {
          fields.forEach(feild => {
            if (feild.fieldName == onchange.options[key]) {
              feild.readOnly = value != key
            }
          })
          dispatch(updateCurrentItemFileds({ data: fields, tab: tab, section: section }))
          
        })
        break;
      case 'filter-field':   
          fields.forEach(feild => {
            if (feild.fieldName == onchange.field) {
              if (!feild.optionsR) {
                feild.optionsR = feild.options
              }
              feild.options = feild.optionsR.filter(o => o[onchange.group] == value);
              feild.value = ''
              feild.readOnly = true;
              feildsData[onchange.field] = '';
       
              setFeildsData(feildsData)
            }
          })
        dispatch(updateCurrentItemFileds({ data: fields, tab: tab, section: section }))
        
       
           fields.forEach(feild => {
            if (feild.fieldName == onchange.field) {            
              feild.readOnly = false;
            }
          })
        dispatch(updateCurrentItemFileds({ data: fields, tab: tab, section: section }))
        break;
      default:
        break;
      }
  }

    const extractValue = (field, value) => {
      if (field.type == 'multiselect' && value) {
        if (typeof value == 'string') {
          return value;
        }
        return value.map(part => typeof part == 'object' && part.value ? part.value : part).join(',')
      }
      if (field.type == 'treeview' && value) {
        
        return JSON.stringify(value)
      }

      return value === undefined ? '' : value;
    }

    const submit = ()  => {
        if(props.mode == 'create') {
          var createFields = {}
          fields.forEach(f => {
            createFields[f.fieldName] = extractValue(f,  feildsData[f.fieldName])
          })
            dispatch(create({
                model: currentModel,
                data: createFields,
                isParent : props.isParent
            }))
        } else {
          var updateFields = {}
          fields.forEach(f => {
            updateFields[f.fieldName] = extractValue(f,  feildsData[f.fieldName])
          })
            dispatch(updateItem({
                model: currentModel,
                id: id,
                data: updateFields,
                isParent : props.isParent
            }))
        }
    }
    if(currentItem?.feildsData?.saved_on_server && currentItem?.feildsData?.id) {
     
      dispatch(getView({
          model: props.model,
          id: currentItem?.feildsData?.id,
        isParent: props.isParent,
          notClear : true
          
        }))
     
  
        if(props.close) {
            props.close({id: currentItem.feildsData?.id, mode: props.mode})
        } 
        //navige(`/${currentModel}/view/${feildsData.Id}`)
        }

    const smallPopup = fields && fields.filter(f => !f.readOnly).length < 6 && !fields.map(f => f.type).includes('editor')
  
   return (
        <section className='content'>
            <Form 
                 onSubmit={submit}
                render={(formRenderProps : FormRenderProps) => (
                  <div className="edit-form">
                    <FormElement style={{
                      maxWidth: smallPopup ? '360px' : '100%',
                      minWidth: '250px',
                    }}
                    className={smallPopup ? '' : 'form-width'}
                    >
                        <fieldset className={"k-form-fieldset"}>
                        
                            <div className="form-content">
                            {fields ? fields.filter(feild => !feild.readOnly).map((field, i) => (
                            <div  className={`mb-3 edit-input ${field.type}_filed ${smallPopup ? 'width-100' : ''} ${field.lineBefore ? 'line-before' : ''} ${field.cssClass || ''}`} >                                
                                <BaseInput autoFocus={i == 0} key={field.fieldName} onChange={UnChange}
                                  feildsData={feildsData}
                                  data={{ ...field, formRenderProps: formRenderProps, model: currentModel, value: feildsData ? feildsData[field.fieldName] : undefined }} />
                                {/* <label className='k-label k-text-error'>{validaionMsg(field.fieldName)}</label>    */}
                            </div>
                            )) : null}</div>
                        </fieldset>

              <Button type={"submit"} onClick={submit} style={{display: 'none'}} />
                 </FormElement>
            <div className="k-form-buttons">
              <div className="width-100">
              {extraActions && extraActions.length > 0 && extraActions.map(action => (
                <ExtraAction action={action} id={id} model={props.model} data={feildsData} isParent={props.isParent}></ExtraAction> 
              ))}

              </div>
              <Button
                className="cancel-btn"
                onClick={Cancel}
                fillMode="outline">
                ביטול
              </Button>
              <Button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base submit-btn"
                onClick={submit}
                 themeColor="warning"
                 disabled={loading}
                //disabled={!formRenderProps.allowSubmit}
              >
                {props.mode == "create" ? "יצירה" : "שמירה"}
                {loading && <Loader themeColor="dark" size="small" />}
              </Button>
            </div>
        </div>
        )}
      />
    </section>
  )
    
};

export default EditForm;
