import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNavbarCurrentItem,
  setItems,
} from "../../../redux/actions/navbarActions";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./Menu.css";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import logoIcon from '../../../assets/images/logo_icon.svg';
import logo from '../../../assets/images/logo-w.svg';
import { setExppandedNav } from "../../../redux/actions/navbarActions";

import { lookup, getIndex, emptyView } from "../../../redux/actions/baseDataActions";
import Base from "../../base/Base";
import Home from "../../home/Home";
import Header from "../header/Header";
import ViewForm from "../../viewForm/ViewForm";
import EditForm from "../../editForm/EditForm";
import BaseView from '../../baseView/BaseView';

const Menu = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navige = useNavigate();
const [activeRouting,setActiveRoute]=useState();
  const base = useSelector((state) => state.base);
  if (localStorage.getItem("selectedIndexInNavBar")) {
    dispatch(
      setNavbarCurrentItem(localStorage.getItem("selectedIndexInNavBar"))
    );
  }

  const { selectedIndexInNavBar, expanded, items } = useSelector(
    (state) => state.navbar
  );
  const expNav = () => {
    dispatch(setExppandedNav(!expanded));
  }; 

  const CustomItem = (props) => {
    const { visible, ...others } = props;
    const arrowDir = props.expanded
      ? "k-i-arrow-chevron-down"
      : "k-i-arrow-chevron-left";

    if (props.logo) {
      return <>
              <div className="menu-logo  k-drawer-item" onClick={expNav}>
                <img className="logo-icon" src={logoIcon}/>
                <img className="logo-w-name" src={logo}/>
              </div>
            </>;
    }

    return (
      <>
        {props.visible === false ? null : (
          <DrawerItem
            className={'menu-item ' +
              props.expanded !== undefined && props.expanded
                ? "parent_active"
                : ""
            }
            {...others}
            title=""
          >
            <a href={props.route} className="menu-item-link" onClick={onSelect}>
           
              
                <Tooltip openDelay={expanded && props.title.length < 25 ? 10000 * 100000 : 100}
                    position="left" anchorElement="target"
                    tooltipClassName="menu-tootip">
              
                  <span title={props.title} className={"k-icon " + props.icon} />
                </Tooltip>
            <span className={"menu-item-text k-item-text"}>{props.text}</span>
                 
            {props.expanded !== undefined &&
                  <Tooltip openDelay={expanded && props.title.length < 25 ? 10000 * 100000 : 100}
                  position="left" anchorElement="target"
                  tooltipClassName="menu-tootip">
            
                  <span  title={props.title}
                    className={"k-icon " + arrowDir}
                    style={{
                      position: expanded ? "absolute" : 'relative',
                      left: expanded ? 10 : 0,
                    }}
                />
                  </Tooltip>}
                </a>
          </DrawerItem>
        )}
      </>
    );
  };

  const onSelect = (ev) => {
    if (ev.preventDefault) {
      ev.preventDefault()
      return
    }

    
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem.expanded !== undefined;
    if (currentItem.route) {
        if (ev.syntheticEvent.ctrlKey) {
          window.open('/' + currentItem.route, '_blank');
        return
      }


      const urlParams = new URLSearchParams()
      if (ev.itemTarget.props.filters) {

        Object.keys(ev.itemTarget.props.filters).forEach((key) => {
          urlParams.append(key, ev.itemTarget.props.filters[key])
        })
      }
      navige(ev.itemTarget.props.route + (ev.itemTarget.props.filters ? ('#' + urlParams.toString() ) : ''));
      if (
        (!currentItem.Component || !currentItem.Component.includes("report")) &&
        ev.itemTarget.props.route != "/" && !ev.itemTarget.props.route.includes('reports') 
      ) {
        dispatch(lookup({ name: ev.itemTarget.props.route }));
      }
      if (
        ev.itemTarget.props.route != "/" 
      ) {
        dispatch(emptyView(true))
        dispatch(
          getIndex({
            ...ev.itemTarget.props.filters, name: ev.itemTarget.props.route, page: 1, "per-page": 100,
            sort: ev.itemTarget.props?.sortBy
          })
        );
      }
    }

    const newData = items.map((item) => {
      const isCurrentItem = currentItem.id == item.id; // || currentItem.parentId == item.id;
      return {
        ...item,
        selected: isCurrentItem,
        expanded:
          item.expanded === undefined
            ? undefined
            : isCurrentItem
            ? !item.expanded
            : item.expanded,
      };
    });

    dispatch(setItems(newData));
  };

  const setSelectedItem = (pathName) => {
    if (!items || !items.length == 0) {
      return "";
    }

    let currentPath = items?.find((item) => item.Url === pathName);

    if (currentPath && currentPath.Name) {
      return currentPath.Name;
    }
  };

  let selected = setSelectedItem(location.pathname);
  const data = items
    ? items
        .filter(item => item.Component != 'hidden')
        .map((item) => ({
          selected:
            item.Url != "" && item.Url?.split('#')[0] == location.pathname.split('/')[1],//replace("/", ""),
          text: item.Name,
          icon: item.Icon,
          route: item.Url?.split('#')[0],
          parentId: item.Parent,
          className: item.Parent && expanded ? "child" : "",
          Component: item.Component,
          expanded: item.expanded,
          Id: item.id,
          id: item.id,
          title: item.Name,
          sortBy: item.Url?.split('#').length > 1 && (new URLSearchParams(item.Url?.split('#')[1])).get('sort')
            ? (new URLSearchParams(item.Url?.split('#')[1])).get('sort')
            : '-Id',
          filters: Object.fromEntries(new URLSearchParams(item.Url?.split('#').length > 1 ? item.Url?.split('#')[1] : '')),

        }))
        .map((item) => {
          const { parentId, ...others } = item;

          if (parentId !== undefined) {
            const parent = items
              ? items.find((parent) => parent.id === parentId)
              : {};
            return {
              ...others,
              visible: parent && parent.expanded,
              parentId: parentId,
            };
          }

          return item;
        })
    : [];
  return (
    <Drawer
      className="my-dr"
      expanded={expanded}
      position={"start"}
      dir="rtl"
      mode={"push"}
      mini={true}
      miniWidth={54}
      items={[
        ...[
          {
            logo: true,
          },
          {
            selected: location.pathname == "/",
            text: "דאשבורד",
            icon: "k-i-home",
            route: "/",
            title: "דאשבורד",
          },
        ],
        ...data,
      ]}
      item={CustomItem}
      onSelect={onSelect}
    >
      <DrawerContent>
        <Header name={location.pathname.split('/')[1] } subName={location.pathname.split('/')[2]}/>
        <Routes>
          <Route key={"home"} path={"/"} element={<Home />} />
         
          {items
            ? items.map((item) => (
                <>
                  <Route
                    key={item.Url?.split('#')[0]}
                    path={item.Url?.split('#')[0]}
                    element={item?.Url?.includes('view') ? <BaseView model={item.Url?.split('#')[0]} isParent={true} /> : <Base mame={item.Url?.split('#')[0]} />}
                  />
                  <Route
                    key={"view-" + item.Url}
                    path={item.Url?.split('#')[0] + "/:id"}
                    element={<ViewForm model={item.Url?.split('#')[0]} isParent={true} />}
                  />    
                  {item.Children
                    ? item.Children.map((child) => (
                        <>
                          <Route
                            key={child.Url}
                            path={item.Url + "/:parentId/" + child.Url}
                            element={<Base mame={child.Url} />}
                          />
                          <Route
                            key={"view-" + child.Url}
                            path={
                              item.Url + "/:parentId/" + child.Url + "/view/:id"
                            }
                            element={
                              <ViewForm
                                model={item.Url + "/{parentId}/" + child.Url}
                              />
                            }
                          />
                        </>
                      ))
                    : null}
                </>
              ))
            : null}
          {
          // <Route key={"home"} path={"*"} element={<Home />} />
          }
        </Routes>
      </DrawerContent>
    </Drawer>
  );
};

export default Menu;
