import {useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useLocation, useNavigate} from 'react-router-dom';
import { Loader } from "@progress/kendo-react-indicators";

import Grid from '../layout/grid/Grid'
import Title from '../layout/title/Title';
import Filters from '../layout/filters/Filters';
import { lookup, getIndex } from '../../redux/actions/baseDataActions'
import Side from "../layout/SidePopup/SidePopup";

import './Base.css'
import TotalCount from './TotalCount';
const Base = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
    const navigate = useNavigate()
    const [urlParams, setUrlParams] = useState(Object.fromEntries(new URLSearchParams(location.hash.replace('#', ''))))
    
  const base = useSelector(state => state.base)
  const { liveActions }  = useSelector(state => state.async)
  
  const locationArr = location.pathname.substring(1).split('/')
  const parent = locationArr[0]
    const currentModel = props.mame
    
    useEffect(() => {
      
        const urlParamsObj = new URLSearchParams(location.hash.replace('#', '') || '')
            const sortParam = urlParamsObj.get('sort')
            if (sortParam) {
                urlParamsObj.delete('sort')
            }
            const page = urlParamsObj.get('page') || 1;
            const take = urlParamsObj.get('take') || 100;
        
            setUrlParams(Object.fromEntries(urlParamsObj))
            
            setParams(Object.fromEntries(urlParamsObj))
           
        if (!liveActions.includes(currentModel)) {
             if ((locationArr.length == 1 || props.lookup) && !base[currentModel]?.LOOKUP) {
                dispatch(lookup({ name: currentModel}))
            }
            dispatch(getIndex({...Object.fromEntries(urlParamsObj), name: currentModel, page: page, 'per-page' : take, sort:  sortParam || '-Id'}))

        }
    },[location.hash])

    
    useEffect(() => {
      

        if ((currentModel && !base[currentModel]) && !liveActions.includes(currentModel)) {
        const urlParamsObj = new URLSearchParams(location.hash.replace('#', ''))
        setUrlParams(Object.fromEntries(urlParamsObj))
        if ((locationArr.length == 1 || props.lookup) && !base[currentModel]?.LOOKUP) {
            dispatch(lookup({ name: currentModel}))
        }
            const sortParam = urlParamsObj.get('sort')
            const page = urlParamsObj.get('page') || 1;
            const take = urlParamsObj.get('take') || 100;
      dispatch(getIndex({...Object.fromEntries(urlParamsObj), name: currentModel, page: page, 'per-page' : take, sort: sortParam || '-Id'}))
    }
  }, [])

  const [params, setParams] = useState(null);
  const [sidepopup,setsidepopup]=useState(false)
  
    const hendlePopup=()=>{
        setsidepopup(true);
    }

    const closePopup=()=>{
        setsidepopup(false);
    }

    const OnfilterChange = (filters) => {
        setParams(filters)
        const urlParams2 = new URLSearchParams(location.hash.replace('#', ''));
        const urlParams = new URLSearchParams()
        urlParams2.forEach((val, key) => {
            if (['sort', 'page', 'take'].includes(key)) {
                urlParams.set(key, val)
            }
        })
        Object.keys(filters).forEach(key => {
            if(filters[key] && key != 'name' && !['sort', 'page', 'take'].includes(key)){
                urlParams.set(key, filters[key])
            }
        })
        const sortParam = urlParams.get('sort')
        const page = urlParams.get('page') || 1;
        const take = urlParams.get('take') || 100;
      dispatch(getIndex({...Object.fromEntries(urlParams), name: currentModel, page: page, 'per-page' : take, sort: sortParam || '-Id'}))
  
        navigate(location.pathname + '#' + urlParams.toString(), { replace : false})

    } 

    const SortGridChange = (sortParam) => {
        const  urlParams = new URLSearchParams(location.hash.replace('#', ''));
        urlParams.set('sort', sortParam || '-Id')
        navigate(location.pathname + '#' + urlParams.toString(), { replace : false})
    }

    const PageGridChange = (page, take) => {
        const  urlParams = new URLSearchParams(location.hash.replace('#', ''));
        urlParams.set('page', page)
        urlParams.set('take', take)
        navigate(location.pathname + '#' + urlParams.toString(), { replace : false})
 
    }

    if(base[currentModel]) {
    
        const settings = base[currentModel]?.LOOKUP?.gridSettings[currentModel]
        return (
            <section className='content'>
              {/* {sidepopup&&<Side onClosePopup={closePopup}/>} */}
                {/* <Title title={base[currentModel]?.LOOKUP?.title}/> */}
                <div className="filters-area">
                    <Filters defParams={urlParams} FilterChanged={OnfilterChange} settings={base[currentModel]?.LOOKUP} model={currentModel}/>
                </div>
                
                {base[currentModel] && base[currentModel]?.data?.total_count && <div><TotalCount model={currentModel} /></div>}
                
                <div className='grid-area'>
                <Grid model={currentModel} isParent={props.isParent === undefined ? true : props.isParent}
                      pageable={settings?.pageable === undefined ? true : !!settings.pageable}
                      actions={settings?.actions}
                      slidePopup={settings?.slidePopup} 
                      filters={params}
                      columns={settings?.columns}
                      moreActions={settings?.moreActions}
                        sortChange={SortGridChange}
                        pageChange={PageGridChange}
                     
                /> 
                </div>
                    
                  
            </section>
        )
    }
    else {
        return (
            <div className='loader' >
                <Loader themeColor="dark" type="converging-spinner" size='large'/>
            </div>
        )    
    }
}

export default Base
