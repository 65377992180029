import { useEffect, useState, createRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateLog, UpdateLog, getLog, SendWhatsApp } from "../../redux/actions/baseDataActions";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import { Avatar } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import TEditor from './TEditor'
import {
  Card,
  CardTitle,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BaseInput from '../layout/baseInput/BaseInput'
import "./Notes.css";


import {
  Editor,
  EditorTools,
  ProseMirror,
  EditorUtils,
} from "@progress/kendo-react-editor";
import {
  addMentionNodes,
  addTagNodes,
  getMentionsPlugin,
} from "prosemirror-mentions";
const { Schema, EditorView, EditorState } = ProseMirror;
const { Bold, Italic, Underline, Link, Unlink, InsertFile } = EditorTools;
const mentionsData = [
  { name: "חיים", id: "103", email: "anna@gmail.com" },
  { name: "John Doe", id: "101", email: "joe@gmail.com" },
  { name: "Joe Lewis", id: "102", email: "lewis@gmail.com" },
];


const getMentionSuggestionsHTML = (items) => {
  return (
    '<div class="suggestion-item-list">' +
    items
      .map((i) => '<div class="suggestion-item">' + i.name + "</div>")
      .join("") +
    "</div>"
  );
};
const Notes = (props) => {
  const { currentLog, startLog } = useSelector((state) => state.base);
  const { loading, liveActions } = useSelector((state) => state.async);
  const { user } = useSelector((state) => state.auth);

  const mentionPlugin = getMentionsPlugin({
  getSuggestions: (type, text, done) => {
    
    setTimeout(() => {
      if (type === "mention") {
        done(user.Users);
      }
    }, 0);
  },
  getSuggestionsHTML: (items, type) => {
    if (type === "mention") {
      return getMentionSuggestionsHTML(items);
    }
  },
});


  const [loadingD, setloadingD] = useState(false);
  const [loadingId, setloadingId] = useState();
  const [newlog, setNewlog] = useState();
  const [filterType, setFilterType] = useState(null);

  const editorRef = useRef()

  const [value, setValue] = useState("");
  useEffect(() => {
    setNewlog(startLog);
  }, [startLog]);

  const [tools] = useState([
    [Bold, Italic, Underline],
    [Link, Unlink, InsertFile],
  ]);
  
  const handleMount = (event) => {
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;
    const marks = schema.spec.marks;
    const nodes = schema.spec.nodes;

    const mySchema = new Schema({
      nodes: addTagNodes(addMentionNodes(nodes)),
      marks,
    });

    const doc = EditorUtils.createDocument(mySchema, event.target.props.defaultContent || '');

    plugins.unshift(mentionPlugin);

    return new EditorView(
      { mount: event.dom },
      {
        ...viewProps,
        state: EditorState.create({ doc, plugins }),
      }
    );
  };

  const dispatch = useDispatch();

  const saveNewLog = () => {
    console.log(currentLog)
    dispatch(
      CreateLog({
        Model: currentLog.model,
        ModelId: currentLog.id,
        Params: newlog,
      })
    );

    setNewlog("");
  };

  const sendWhatsapp = () => {
    dispatch(SendWhatsApp({
        Model: currentLog.model,
        ModelId: currentLog.id,
        Params: newlog,
     
    }))
      setNewlog("");
  }

  
  const ChangeType = (e, value) => {
    setFilterType(value);
  }

  const showMore = () => {
    dispatch(getLog({page: (Math.max(parseInt(currentLog.rows.length / 20), 1) + 1), model: currentLog.model, id: currentLog.id}))
  }

  const LogItemRender = (props) => {
    let item = props.dataItem;
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState();
    const [updateText, setUpdateText] = useState();

    const deleteLogItem = async (id) => {
      setloadingD(true);
      setloadingId(id);
      await dispatch(
        UpdateLog({
          id: id,
          Deleted: "1",
        })
      );
      setloadingD(false);
      setloadingId();
    };

    const ThumbtackLogItem = () => {
      dispatch(
        UpdateLog({
          Id: item.id,
          Thumbtack: item.Thumbtack == "1" ? 0 : 1,
        })
      );
    };

    const UpdateLogItem = () => {
      
      dispatch(
        UpdateLog({
          id: item.id,
          Params: updateText,
        })
      );
      setEdit(false);
    };

    const ItemClick = (e) => {
      switch (e.item.actionName) {
        case "מחיקה":
          deleteLogItem(item.id);
          break;
        case "עריכה":
          setText(item.Text);
      
          setTimeout(() => {
                setEdit(edit ? false : item.id);
          }, 300); 
          break;
        case "הסר הצמדה":
        case "הצמדה":
          ThumbtackLogItem();
          break;
        default:
          break;
      }
    };

    if (item.type == 'more-btn') {
      return (currentLog.rows.length < currentLog.count && <a className="show-more" onClick={showMore}>הצג עוד</a>)
    }

    return (
      <Card
        orientation="horizontal"
        className="d-flex justify-content-between note"
      >
        <div className="content_note k-vbox k-column">
          <div>
            <CardTitle
              style={{
                fontSize: 14,
              }}
            >
              <Avatar
                type="text"
                size="small"
                style={{
                  backgroundColor: item.Color ? item.Color : "#9f9f9f",
                  marginTop: "-3px",
                  width: "20",
                  height: "20",
                }}
              >
                <span className="avatar_text">
                  {" "}
                  {item.Name ? item.Name.substr(0, 1) : ""}{" "}
                </span>
              </Avatar>
              <span> {item.Name}</span>
              {!edit && (
                <DropDownButton
                  popupSettings={{ popupClass: "ddb-popup", animate: false }}
                  textField="actionName"
                  className="actions-btn pull-left"
                  items={[
                    {
                      actionName: item.Thumbtack == "1" ? "הסר הצמדה" : "הצמדה",
                      icon: item.Thumbtack == "1" ? "pin" : "unpin",
                    },
                    {
                      actionName: "עריכה",
                      icon: "edit",
                      disabled: item.IsEdit != "1",
                    },
                    {
                      actionName: "מחיקה",
                      icon: "delete",
                    },
                  ]}
                  fillMode="flat"
                  icon="more-horizontal"
                  onItemClick={ItemClick}
                />
              )}
              {item.Thumbtack == "1" && (
                <span className="k-icon k-i-pin pull-left"></span>
              )}

              <sub className="pull-left date-note"> {item.Created}</sub>
            </CardTitle>
            {loadingD && loadingId == item.id ? (
              <span className="loading_span">
                <Loader type={"pulsing"} themeColor={"inverse"} />
              </span>
            ) : (
              ""
            )}
            {edit ? (
              <div className="log-grid">
                <textarea className="log-input-edit" value={text} onChange={(e) => setText(e.target.value)}></textarea> 
      
             <Button
                  className="insert-log"
                  onClick={() => UpdateLogItem()}
                  themeColor={"info"}
                >
                  {" "}
                  עדכון{" "}
                </Button>
              </div>
            ) : (
              <CardSubtitle
                style={{
                  fontSize: 13.1,
                  marginTop: 0,
                  minHeight: 30,
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.Text.replace("\n", "<br/>"),
                  }}
                ></span>
              </CardSubtitle>
            )}
          </div>
        </div>
      </Card>
    );
  };

  const ChangeTemplate = (e, value) => {
    
    let msg = user.templates.find(t => t.id == value);
    if(msg) {
      setNewlog(msg.content)
    }
  }

  return (
    <div>
    
      <h4 className="note-title">הסטוריה ועדכונים</h4>
      {props.sendWhatsapp && 
        <>
        <BaseInput 
         data={{type: 'select', 
              clear:  !!newlog, 
              label: 'הודעות', options: user.templates.map(t => { return {...t, value: t.id}})}}
         onChange={ChangeTemplate}/>
        <br/>
        </>}

    <textarea
        placeholder="כתיבת הודעה ועדכון"
          className="log-input"
          value={newlog}
          onChange={(e) => setNewlog(e.target.value)}
      ></textarea>
      <div className="log-grid">

        <div className="log-btn-grid">
        <Button
          className="insert-log"
          disabled={!newlog}
          onClick={() => saveNewLog()}
          themeColor={"info"}
        >
          עדכון
        </Button>
        {props.sendWhatsapp && <Button
          disabled={!newlog}
          onClick={() => sendWhatsapp()}
          themeColor={""}
          className=""
          iconClass="fa-brands fa-whatsapp"
        >
          שלח Whatsapp
        </Button>}
        </div>
       
      </div>
      {loading && liveActions.includes(props.model) && (
        <div className="loading_span">
          {" "}
          <Loader type="converging-spinner" themeColor="inverse" />
        </div>
      )}
      <ListView
        className="list_notes"
        data={currentLog?.rows ? [...currentLog.rows.filter(r => {
          return filterType == null || r.Type == filterType
        }), ...[{
          type: 'more-btn'
        }]] : []}
        item={LogItemRender}
      />
    </div>
  );
};

export default Notes;
