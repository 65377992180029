import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Side from "../SidePopup/SidePopup";
import { toast } from 'react-toastify';
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridToolbar,
  GridPagerSettings,
  GRID_COL_INDEX_ATTRIBUTE,
  getSelectedState
} from "@progress/kendo-react-grid";

import { getter } from "@progress/kendo-react-common";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Tooltip } from "@progress/kendo-react-tooltip";

import {
  getIndex,
  deleteItem,
  enterEdit,
  getLog,
  create,
  startLog,
  UpdateMutliItems
} from "../../../redux/actions/baseDataActions";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import DeleteModal from "../deleteModal/DeleteModal";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import EditForm from "../../editForm/EditForm";
import {
  getView,
  updateItem,
  convert,
  getCreate,
} from "../../../redux/actions/baseDataActions";
import { asyncActionError , asyncActionStart} from '../../../redux/actions/asyncActions'
import axios from "axios";
import "./Grid.css";
import { close, open } from "../../../redux/actions/modalActions";
import BaseInput from "../baseInput/BaseInput";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import calculateSize from "calculate-size";
import messagesObj from "../lang/he.json";
import Retro from '../../special/retro/Retro'
import Relation from "../../special/relation/Relation";
import SendLead from "../../special/SendLead";
const idGetter = getter('id');

function Grid(props) {
  const modelName = props.model;
  const location = useLocation();
  const title = props.title || '';
  const isParent = props.isParent;
  const base = useSelector((state) => state.base);
  const { loading } = useSelector((state) => state.async);
  const { liveActions }  = useSelector(state => state.async)
  const cellChangedFunc = props.cellChanged

  var currentItem = props.isParent ? base.currentItem : base.currentItemChild;

  const model = useSelector((state) => state.base[props.model]);
  const { modal_name } = useSelector((state) => state.modal);
  const language = "he";
  
  const mPage = model?.LOOKUP?.gridSettings[modelName]?.page 
  const mtake = model?.LOOKUP?.gridSettings[modelName]?.take 
  const filters = model?.LOOKUP?.gridSettings[modelName]?.filters 

  loadMessages(messagesObj, language);
  const createState = (skip, take, page) => {
    let pagerSettings = model?.data?.count > 5 && props.pageable
      ? {
          buttonCount: 10,
          info: true,
          type: "numeric",
          pageSizes: [5, 10, 20 ,100],
          previousNext: true,
        }
      : false;

    return {
      skip: skip ,
      pageSize: take,
      pageable: pagerSettings,
      page: mPage,
    };
  };
  const [state, setState] = React.useState(createState(mPage == 1 ? 0 : ((mPage -1) * mtake), mtake ? mtake : 100, mPage));

  useEffect(() => {
    setSelected(null)
    setsidepopup(false);
    setState(createState(mPage == 1 ? 0 : ((mPage - 1) * mtake), mtake ? mtake : 100, mPage))
    setSort('')
    setSelectedState({})
  }, [modelName])
  
  useEffect(() => {
    setState(createState(mPage == 1 ? 0 : ((mPage -1) * mtake), mtake ? mtake : 100, mPage))
  }, [model?.data?.count])
  const dispatch = useDispatch();
  const navige = useNavigate();
  const actions = props.actions;
  const columns = props.columns;
  const setTab = props.setTab;
  const setTabSelected = props.setTabSelected;

  //leads open sidepopup
  const sidePopup = props.slidePopup;
  const [selctedRowId, setSelected] = React.useState(null);
  const [selectedAll, setSelectedAll] = React.useState(null);

  const clickRow = (event) => {
    if (event.dataItem.id == selctedRowId) {
      setSelected();
      closePopup();
    } else {
     
      if (sidePopup) {
        setSelected(event.dataItem.id);

        openPopup();
        dispatch(getLog({ model: modelName, id: event.dataItem.id }));
      }
    }

  };
  const [sidepopup, setsidepopup] = useState(false);


   const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = React.useCallback(
    (event) => {
      if (!event.syntheticEvent.target.checked) {
        setSelectedAll(false)
      }
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: 'id',
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const openPopup = () => {
    setsidepopup(true);
  };

  const closePopup = () => {
    setsidepopup(false);
  };

  const rowRender = (trElement, props) => {
    const LineColor = props.dataItem.LineColor;

    const trProps = {
      style: { backgroundColor: LineColor },
      className: 'no-white'
    };

    if (LineColor) {
      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
    }
    return React.cloneElement(trElement, trElement.props.children);
  };

  const [visible, setVisible] = React.useState(null);

  const [editId, setEditId] = useState(null);

  const sortBy = model?.LOOKUP?.gridSettings[modelName]?.sort 
  const [sort, setSort] = React.useState(
    { field: sortBy ? sortBy.replace('-', '') : "id", dir: sortBy ? (sortBy.indexOf('-') === 0 ? 'desc' : 'asc') :  "desc" },
  );

  const getTitle = (col) => {
    if (col.title) {
      return col.title;
    }

    return model?.LOOKUP?.labels && model.LOOKUP.labels[col] ? model.LOOKUP.labels[col] : col;
  };

  const ChangeMulti = (e, value, send) => {
    
    if (send === false) {
      return;
    }

   if(e.type == 'multiselect' && value) {
     value = value.map(part => typeof part == 'object' && part.id ? part.id : part)
   }
    let data = {}
    data[e.field] = value
     
    
    var selected = Object.keys(selectedState);
    dispatch(UpdateMutliItems({
      model: modelName,
      isParent: props.isParent,
      data: { data, ids: selected.filter(s => selectedState[s]) },
      fresh: {
        ...filters,
        name: modelName,
        page: state.page,
        "per-page": state.pageSize,
        sort: (sort ? ((sort.dir == "desc" ? "-" : '') + sort.field) : '-Id'),

      }
    }))

  }


  
  const getWidthCell = (col) => {
   
    if ((col == "id" || col.field == 'id') && props.columns.filter(c => typeof c == 'string' || c.type != 'disabled').length > 4) {
    //  return 100;
      return calculateWidth(typeof col == "string" ? col : col.field, getTitle(col)) + 10;
    }

    if (col == "Passport" || col.field == 'Passport') {
    //  return 100;
      return calculateWidth(typeof col == "string" ? col : col.field, getTitle(col)) + 5;
    }

    if (typeof col == 'object' && col.type == 'disabled') {
      return 0;
    }

    if (col.calcWidth) {
        return calculateWidth(typeof col == "string" ? col : col.field, getTitle(col)) + 5;
    }
    
    
    if ((col.type == 'select'|| col.type == 'multiselect') && props.columns.filter(c => typeof c == 'string' || c.type != 'disabled').length > 4) {
      let maxWidth = 0;
        col.list.forEach((item) => {
         const size = calculateSize(item.name, {
           font: "Arial",
           fontSize: "15px",
         }); // pass the font properties based on the application
         if (size.width > maxWidth) {
           maxWidth = size.width;
         }
        });
      return Math.max(calculateSize(title, {
       font: "Arial",
       fontSize: "12px",
      }).width, maxWidth + 70) + 50;
    }

    let maxCols = window.innerWidth < 1900 ? 16 : 20
     if (
      (props.columns.filter((c) => c.children).length == 0 && props.columns.filter(c => typeof c == 'string' || c.type != 'disabled').length < maxCols && props.isParent) || (!props.isParent )
     ) {
      return false;
    } 
    return calculateWidth(typeof col == "string" ? col : col.field, getTitle(col))
  };

  const getEditor = (col) => {
    if (typeof col == "string" || !col.type || col.type == "link") {
      return null;
    }

    return col.type;
  };


  const createTxt = props.section?.modelCreateTitle || 'יצירת';
  
  const deleteText = model?.LOOKUP?.modelName
    ? model.LOOKUP.modelName
    : (props.section?.modelName
    ? props.section.modelName
    : "");
  const btnTxt = model?.LOOKUP?.modelName
    ? createTxt + " " + model.LOOKUP.modelName
    : (props.section?.modelName
    ?  createTxt + " " + props.section.modelName
    : "חדש");

  let pagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100],
    previousNext: true,
  };

  const pageSort = (event) => {
    let sortParam = null;
    let field = null;
    let dir = null;
    if (actions?.includes('no-sort')) {
      return;
    }
    if (event.sort.length > 0) {
      field = event.sort[0].field;
      sortParam = event.sort[0].dir == "desc" ? "-" + field : field;
      pageChange(event, state.page, state.skip, state.pageSize, sortParam);
      setSort(event.sort[0]) 
    }
    else {
      field = sortBy.replace('-', '')
      dir = (field == sortBy ? 'desc' : 'asc');
      sortParam = (dir == "desc" ? "-" : '') + field;
      pageChange(event, state.page, state.skip, state.pageSize, sortParam);
        setSort({field : field , dir: dir}) 
    };
    if (props.sortChange) {
      props.sortChange(sortParam)
    }


  };

  const changePageSize = (event) => {
    let sortParam;
    let page = event.page.skip > 0 ? (event.page.skip / event.page.take ) + 1: 1;
    let take = event.page.take;
    
    setState(createState(event.page.skip, event.page.take, page));

    if (typeof sort == 'object') {
      sortParam = sort.dir == "desc" ? "-" + sort.field : sort.field;
    } else {
      sortParam = sort;
    }

    
    pageChange(event, page, event.page.skip, take, sortParam);

    if (props.sortChange) {
      props.pageChange(page, take)
    }
  };

  const pageChange = (event, page, skip, take, sortParam) => {
    const filters = props.filters || {}


    if (sortParam) {
      dispatch(
        getIndex({
          ...filters,
          name: props.model,
          page: page,
          "per-page": take,
          sort: sortParam,
        })
      );
    } else {
      dispatch(
        getIndex({
          ...filters,
          name: props.model,
          page: page,
          "per-page": take,
          sort: '-Id'
        })
      );
    }
    setState(createState(skip, take, page));
    
  };

  useEffect(() => {
    if (props.model && (!model &&  !liveActions.includes(props.model))) {
      dispatch(
        getIndex({
          name: props.model,
          sort: (props?.sortBy ? props.sortBy : "-Id"),
          page: 1,
          "per-page": 100,
        })
      );
    }
  }, []);

  const FreshGrid = () => {
    const filters = props.filters || {}

        dispatch(getIndex({
          ...filters,
          name: modelName,
          page: state.page,
          "per-page": state.pageSize,
          sort: (sort ? ((sort.dir == "desc" ? "-" : '') + sort.field) : '-Id'),


        }))

  }

  const deleteAll = () => {
    var selected = Object.keys(selectedState)
    /*dispatch(deleteItem({
      model: modelName,
      id: selected.filter(s => selectedState[s]).join(','),
    }))*/

     var date = new Date();

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    const data = { 'deleted_at' : (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)};

    dispatch(UpdateMutliItems({
      model: modelName,
      data: { data, ids: selected.filter(s => selectedState[s]) },
      isParent: props.isParent,
      fresh: {
        ...filters,
        name: modelName,
        page: state.page,
        "per-page": state.pageSize,
        sort: (sort ? ((sort.dir == "desc" ? "-" : '') + sort.field) : '-Id'),

      }
    }))

    setSelectedState({})
    dispatch(close())
  }

  const _export = React.useRef(null);
  const _grid = React.useRef();

  const fullExport = () => {
    if (!selectedAll || (props.actions && props.actions.includes("client-export"))) {
      excelExport();
      return;
    }
    var api_url = process.env.REACT_APP_API_URL;

    var export_route = props.model;
    
    var api_url = process.env.REACT_APP_API_URL;

    const urlParams = new URLSearchParams();
    if (filters) {
      Object.keys(filters).forEach((key) =>
        urlParams.append(key, filters[key])
      );
    }

    if (!props.isParent && props.model.split('/') > 2) {
      export_route = props.model.split('/')[0]
      urlParams.append('id', props.model.split('/')[1])
      urlParams.append('childModel', props.model.split('/')[2])
      
    }

    axios({
      method: "post",
      data: {
        columns: props.columns.map(col => {
          return {
            title: getTitle(col),
            field: typeof col == "string" ? col : col.field          
        }
      }), export_route: export_route },
      url: api_url + "/" + export_route + "/export/?" + urlParams.toString(),
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", export_route + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      //    document.location = process.env.REACT_APP_API+'/export_uploads/'+res.data+'.xlsx';
      //    axios.get(api_url+'/'+export_route+'/export?finish='+res.data)
    });

    //alert(JSON.stringify(res.data)))
  };
  const excelExport = () => {
    if (_export.current !== null) {
      
      _export.current.save(); /*_grid.current.data, _grid.current.columns.filter(c => {
        return c.field != "selected" && c.noExport != true;
      }));*/
    }
  };

  const closeDialog = (data) => {
    dispatch(close());
    if (data?.id) {
      if (props.isParent && props.model != 'dt-leads') {
       
        navige(`/${modelName}/${data.id}`);
      } else if (modelName == 'dt-leads') {
        window.location = `/leads/${currentItem.feildsData.id}`
         
      } else {
        // fresh table
        dispatch(
          getIndex({
            name: props.model,
            page: 1,
            "per-page": 100,
            sort: sortBy || "-id",
          })
        );
      }
    }
  };

  const CreateBtn = () => {
    dispatch(open("create_" + props.model));
    if (model?.LOOKUP?.createFeilds) {
      dispatch(
        getCreate({
          fields: model?.LOOKUP?.createFeilds,
          feildsData: model?.LOOKUP?.feildsData,
          model: props.model,
          title: model?.LOOKUP?.title,
          isParent: props.isParent,
        })
      );
    } else {
      dispatch(
        getView({ model: props.model, id: 0, isParent: props.isParent })
      );
    }
  };

  const createBtnGeneric = (model) => {
    dispatch(open("create_" + model));
    dispatch(
      getView({ model: model, id: 0, isParent: props.isParent })
    );
  }


  const BtnGeneric = (action) => {
      switch(action.type) {
        case 'create':
          createBtnGeneric(action.link);
          break;
        case 'request':
          dispatch(asyncActionStart())
            axios[action.method](process.env.REACT_APP_API + "/" + action.link, action.params)
              .then((res) => {
                    dispatch(getIndex({
                    name: props.model,
                    sort: "-" + (props?.sortBy ? props.sortBy : "id"),
                    }))
                
                    
                    const freshNotes = res.headers['x-reload-notes']
                    if(freshNotes) {                      
                      const fresh = JSON.parse(freshNotes)                    
                      if (fresh.model == props.model.split('/')[0]) {
                        dispatch(getLog(fresh))
                      }
                    }

                    const freshView = res.headers['x-reload-view']
                    if(freshView) {
                      dispatch(getView({
                        model: JSON.parse(freshView).model,
                        id: JSON.parse(freshView).id,
                        isParent: JSON.parse(freshView).isParent,
                        notClear: true
                      }))          
                    }
                  
                    const freshIndex = res.headers['x-reload-index']
                    if(freshIndex) {          
                      dispatch(getIndex({
                          name: JSON.parse(freshIndex).name,
                          sort:  JSON.parse(freshIndex).sortBy || "-Id",
                          page: 1,
                          "per-page": JSON.parse(freshIndex).isParent ? 100 : 5,
                      }))
                   }
          
                  const message = res.headers['x-message']
                  if(message) {
                    JSON.parse(message).forEach(m => {
                      toast(m, {
                        type: 'warning',
                        position: "top-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        });
                      })

                  }
                }
              ).catch((err) => {
         
                let msg = err.response?.data?.message || 'חלה שגיאה כללית'
                dispatch(asyncActionError(msg))
              })
          break;
        case 'request_navigate':
           dispatch(asyncActionStart())
            axios[action.method](process.env.REACT_APP_API + "/v1/" + action.link, action.params)
              .then(({ data }) => {
            
                 window.location =  "/" + action.navigateTo + data?.data.id 
                 
                }
              ).catch((err) => {
         
                let msg = err.response?.data?.message || 'חלה שגיאה כללית'
                dispatch(asyncActionError(msg))
              })
          break;
          default:
            break;

      }
  }


  const myCell = (props) => {
    //  const navigationAttributes = useTableKeyboardNavigation(props.id);
    const onChange = (e, value, sendToServer = true) => {
      if (!sendToServer) {
        return;
      }
      
      var feildsData = {};
      feildsData[e.field] = value ? (typeof value == "object" ? value.join(','): value) : null;

      if(modelName == 'professionals-easy') {
        feildsData[e.field] = props.dataItem[e.field.replace('active', 'id')];
        if(!feildsData[e.field]) {
          return;
        }
      }

      if (cellChangedFunc) {
        cellChangedFunc(props.dataItem, e, value)
        return
      }
      if (e.confirm) {
        dispatch(close())
        dispatch(open(e.confirm + props.dataItem.id))
        return;
      }

      if (e.field == 'IsActive' && !value) {
        dispatch(open('is_active_' + modelName +  props.dataItem.id))
        return
      }

      
      if (e.field == 'status' && value == 'הסתיים' && (props.dataItem.status == 'העתקה'
        || model?.data?.rows?.filter(row => row.status != 'הסתיים').length == 1)) {
        dispatch(open('status_' + modelName +  props.dataItem.id + '|||' + JSON.stringify(feildsData)))
        return
      }
      
      dispatch(
        updateItem({
          model: modelName,
          id: props.dataItem.id,
          data: feildsData,
          isParent: props.isParent,
          isInline: true,
          field : e.field
        })
      );

    };

    if (props.field == "id" && actions && actions.includes("view")) {
      return (
        <td className="td_id">
          <a
            className="grid-link"
            onClick={() => isParent ? navige(`/${modelName}/${props.dataItem.id}`) :window.location.replace(`/${modelName}/${props.dataItem.id}`)}
          >
            {props.dataItem[props.field]}{" "}
          </a>
          {props.dataItem.PointColor && (
            <span
              class="freckle"
              style={{ backgroundColor: props.dataItem.PointColor }}
            ></span>
          )}
        </td>
      );
    }
    let cell = columns?.find(
      (c) => c == props.field || c?.field == props.field
    );
    if (typeof cell == "object") {
      switch (cell?.type) {
        case "boolean":
          
          return (
            <td>
              {
               props.dataItem?.IsEdit == 0 && cell.is_edit_only ? props.dataItem[props.field] :
                <BaseInput
                  data={{
                    ...cell,
                    value: props.dataItem[props.field] == "1",
                    type: "checkbox",
                  }}
                  onChange={onChange}
                />
              }
            </td>
          );
        case "text":
        case "number":
          return (
            <td>
              {
                <BaseInput
                  data={{
                    ...cell,
                    label: '',
                    placeholder: '',
                    value: props.dataItem[props.field],
                    type: cell?.type,
                    isInline : true
                  }}
                  onBlur={onChange}
                />
              }
            </td>
          );
        case "selected-link":
          return (
            
            <td>
              <a className="grid-link"
                onClick={(e) => {
                  e.preventDefault();
                 // if (setTabSelected) {
                    setTabSelected(props.dataItem[cell.linkToId])
                  //}
                }}
              > {" "}{props.dataItem[props.field]}{" "}
              </a>
            </td>
          );
        case "link":
          if (props.dataItem.isLink === '0') {
            return  <td>{props.dataItem[props.field]}</td>;
          }
          return (
            <td>
              <a
                className="grid-link"
                download={cell.download}
                target={cell.target || null}
                onClick={(e) => {
                  if (!cell.linkTo.includes('http') && isParent) {
                    e.preventDefault();
                    if(e.ctrlKey) {
                      window.open(`/${cell.linkTo}/${props.dataItem[cell.linkToId]}` + (cell.hash || ''), '_blank')
                    } else {
                    navige(`/${cell.linkTo}/${props.dataItem[cell.linkToId]}` + (cell.hash || ''))
                    }
                  }
                }}
                href={cell.linkTo.includes('http') || (props.dataItem[cell.linkToId] && props.dataItem[cell.linkToId].toString().includes('http'))
                  ? `${cell.linkTo}${cell.linkTo ? '/': ''}${props.dataItem[cell.linkToId]}${cell.hash || ''}`
                  : `/${cell.linkTo}/${props.dataItem[cell.linkToId]}${cell.hash || ''}`
                }
              >
                {" "}
                {props.dataItem[props.field]}{" "}
              </a>
            </td>
          );
        case 'multi-links':
          if (!props.dataItem[cell.field] ||!JSON.parse(props.dataItem[cell.field])) {
            return (<td>{ props.dataItem[cell.field] }</td>)
          }

          return (
            <td>
              {JSON.parse(props.dataItem[cell.field]).map((link) => {
                return (
              <a
                className="grid-link"
                download={cell.download}
                target={cell.target || null}
                onClick={(e) => {
                  
                  if (!cell.linkTo.includes('http') && isParent) {
                    e.preventDefault();
                    navige(`/${cell.linkTo}/${link.link}`)
                  }
                }}
                href={cell.linkTo.includes('http') ? `${cell.linkTo}/${link.link}}` :  `/${cell.linkTo}/${link.link}`}
              >
                {" "}
                {link.name}{" "}
                  <br/></a>
                  )
              }) }
            </td>
          )
        case "img":
          if(!props.dataItem[props.field]) {
            return (
              <td></td>
            );
          }
          return (
            <td>
              <img
                style={{ maxWidth: "100%", maxHeight: "70px" }}
                src={
                 (props.dataItem[props.field].indexOf('http') > -1 ? '' : (process.env.REACT_APP_API + "/")) + props.dataItem[props.field]
                }
              ></img>
            </td>
          );
        case "select":
          return (
            <td>
              {props.dataItem?.IsEdit == 0
                ? (cell.list?.find(
                  (o) =>  o[cell.list[0].id ? "id" : "value"] == props.dataItem[props.field] 
                  )?.name)      
                : <BaseInput
                  data={{
                    ...cell,
                    label: '',
                    value: props.dataItem[props.field],
                    options: cell.list,
                    type: "select",
                  }}
                  onChange={onChange}
                />
              }
            </td>
          );
        case "multiselect":
          return (
            <td>
              {props.dataItem?.IsEdit == 0
                ? (cell.list?.filter(
                  (o) =>  o[cell.list[0].id ? "id" : "value"] == props.dataItem[props.field] 
                  ).map(o => o.name).join(', '))      
                : <BaseInput
                  data={{
                    ...cell,
                    value: props.dataItem[props.field],
                    options: cell.list,
                    type: "multiselect",
                    label: '',
                    placeholder: '',
                  }}
                  onChange={onChange}
                />
              }
            </td>
          );
        case 'bool-icon':
          return (
            <td>
              <span class={"k-icon " + (props.dataItem[props.field] && Number(props.dataItem[props.field]) ? 'k-i-check' : 'k-i-x')} ></span>
            </td>
          )
        case 'bg-color':
          if (!cell.linkTo || props.dataItem.isLink === '0') {
            return (
              <td>
                <span style={{ padding: '4px', borderRadius: '4px', backgroundColor: props.dataItem[cell.bgField], }}>  {props.dataItem[props.field] ? props.dataItem[props.field] : '--------'} </span>
              </td>
            )
          }

          return (
            <td>
              <a
                className="grid-"
                style={{ padding: '4px', borderRadius: '4px', backgroundColor: props.dataItem[cell.bgField], }}
                onClick={(e) => {
                  
                  if (!cell.linkTo.includes('http') && isParent) {
                    e.preventDefault();
                    navige(`/${cell.linkTo}/${props.dataItem[cell.linkToId]}` + (cell.hash || ''))
                  }
                }}
                href={cell.linkTo.includes('http') ? `${cell.linkTo}/${props.dataItem[cell.linkToId]}${cell.hash || ''}` :  `/${cell.linkTo}/${props.dataItem[cell.linkToId]}${cell.hash || ''}`}
              >
                {" "}
                {props.dataItem[props.field] || '--------'}{" "}
              </a>
            </td>
          );

        case 'popover':
          return (
            <td>
              {props.dataItem[props.field] &&
                <Tooltip openDelay={100} position="auto" tooltipStyle={{ maxWidth: '400px', whiteSpace: 'pre-line', border: '1px solid black', direction: 'rtl', textAlign: 'justify' }}>
                  <div title={props.dataItem[props.field]} style={{ 'whiteSpace': 'pre-line' }}>
                    {props.dataItem[props.field]?.length < 20 ? props.dataItem[props.field] : (props.dataItem[props.field]?.substr(0, 20) + props.dataItem[props.field]?.substr(20).split(' ')[0] + ' ...')}
                  </div>
                </Tooltip>}
            </td>
          )
        case 'popup_user_roles':
          const map = ['העתקה', 'עריכת פרטיטורה', 'הגהת פרטיטורה', 'עריכת תפקידים', 'הגהת תפקידים']
          const inst_count = Number(props?.dataItem?.inst_count)

          const link = props.dataItem.link_user_roles?.split(',') || []
          
          const unlink = map.filter(r => inst_count != 1 || !r.includes('תפקידים')).filter(r => !link.includes(r))
          
          return (
            <td>
              {props.dataItem[props.field] && 
                <Tooltip openDelay={100} position="auto" tooltipStyle={{ maxWidth: '400px', whiteSpace: 'pre-line', border: '1px solid black', direction: 'rtl', textAlign: 'justify' }}>
                
                  <span title={unlink.length > 0 ? ('חסר שיבוץ עבור התפקידים הבאים: \n - ' + unlink.join('\n - ')) : 'כלל התפקידים שובצו'}
                    class={"k-icon " + (unlink.length > 0 ? 'k-i-warning' : 'k-i-check')}
                    style={{color: 'rgb('+ (255/ (link.length || 1)) +',' + (255 / (unlink.length || 1) ) +',0)'}}></span>
                </Tooltip>}
            </td>
          )
        case 'priority':
          return (
            <td><span className="priority"> 
              {props.dataItem[props.field] && parseInt(props.dataItem[props.field]) &&
                Array(parseInt(props.dataItem[props.field])).fill('*').join('')
            }</span></td>
          )
        default:
          return <td><span>{props.dataItem[props.field]}</span></td>;
      }
    }

    return <td>{props.dataItem[props.field]}</td>;
  };
   const calculateWidth = (field, title) => {
     let maxWidth = 0;

     if (model?.data?.rows) {
        model.data.rows.forEach((item) => {
         const size = calculateSize(item[field], {
           font: "Arial",
           fontSize: "15px",
         }); // pass the font properties based on the application
         if (size.width > maxWidth) {
           maxWidth = size.width;
         }
       });
     }
     const titleSize = calculateSize(title, {
       font: "Arial",
       fontSize: "12px",
     });
     return Math.max(maxWidth, titleSize.width) + 20;
   };
  const ActionsCell = (props) => {
    const enter = (action) => {
      switch (action) {
        case "view":
          navige(`/${modelName}/${props.dataItem.id}`);
          break;
        case "update":
          dispatch(
            getView({
              model: modelName,
              id: props.dataItem.id,
              isParent: isParent,
            })
          );
          setEditId(props.dataItem.id);
          console.log("edit_" + modelName + "_" + props.dataItem.id)
          dispatch(open("edit_" + modelName + "_" + props.dataItem.id));
          setVisible("edit");
          break;
        case "inline-update":
          dispatch(
            enterEdit({
              model: modelName,
              id: props.dataItem.id,
              data: props.dataItem,
              inEdit: true,
            })
          );
          break;
        case "close":
          dispatch(
            enterEdit({
              model: modelName,
              id: props.dataItem.id,
              inEdit: false,
            })
          );
          break;
        case "save":
          //dispatch(saveInline({ model : modelName,}))
          break;
        case "delete":
          dispatch(open("delete_" + modelName + "_" + props.dataItem.id));
          break;
        case "delete-without-confirm":
          deleteRow()
          break;
        case "convert":
          dispatch(convert({ model: modelName, id: props.dataItem.id }));
          break;
        case "inline-create":
          dispatch(
            create({
              model: modelName,
              data: { Id: props.dataItem.id },
              isInline: true,
            })
          );
          setTimeout(() => {
            setTab();
          }, 800);
          break;
      }
    };

    const deleteRow = () => {
      setVisible(null);
      dispatch(
        deleteItem({
          model: modelName,
          id: props.dataItem.id,
        })
      );
    };

    const updateStatus = () => {
      let feildsData = modal_name.split('|||')[1]
      feildsData = JSON.parse(feildsData);
       dispatch(
        updateItem({
          model: modelName,
          id: props.dataItem.id,
          data: feildsData,
          isParent: props.isParent,
          isInline: true,
          field : 'status'
        })
      );
      dispatch(close());
    }

    const updateToIsNotActive = () => {
      dispatch(close())  
      dispatch(
        updateItem({
          model: modelName,
          id: props.dataItem.id,
          data: { IsActive : 0},
          isParent: props.isParent,
          isInline: true,
          field : 'IsActive',
        })
      );
    }

    const SwitchComponent = (name) => {
      switch (name.name) {
        case 'retro':
          return <Retro data={props.dataItem} fresh={FreshGrid} />
        case 'relation':
          return <Relation data={props.dataItem} />
        case 'sent_with_ci':
          return <SendLead data={props.dataItem} withCi={true} fresh={() => {FreshGrid()}} />;
        case 'sent_without_ci':
          return <SendLead data={props.dataItem} withCi={false} fresh={() => {FreshGrid()}} />;
        default:
          return '--';
      }
    }

    const getIcon = (action) => {
      const map = {
        delete: "delete",
        view: "eye",
        update: "edit",
        "inline-update": "edit",
        "inline-create": "filter-add-expression",
        convert: "user",
        "delete-without-confirm" : "delete",
      };

      if (map[action]) {
        return map[action];
      }
      return action;
    };
    const getActionTitle = (action) => {
      if (typeof action == "object" && action.title) {
        return action.title;
      }

      const map = {
        delete: "מחיקה",
        view: "תצוגה",
        update: "עריכה",
        "inline-update": "עריכה",
        convert: "יצירת רופא חדש",
        "delete-without-confirm" : 'מחיקה'
      };

      if (map[action]) {
        return map[action];
      }
      return action;
    };
    const getColor = (action) => {
      const map = {
        delete: "light",
        view: "success",
        update: "info",
        "inline-update": "info",
        convert: "danger",
        "delete-without-confirm" : "light"
      };

      if (map[action]) {
        // return map[action];
        return "auto";
      }
      return action;
    };

    if (props.dataItem.IsEdit === "0") {
      return <td></td>;
    }

    return (
      <td>
        {actions
          .filter(
            (action) =>
              action != "create" &&
              action != "view" &&
              action != "inline-update" &&
              action != 'no-sort' &&
              action != 'no-export'
          )
          .map((action) => 
            {
            return typeof action == 'object' && action.component
              ? <SwitchComponent name={action.component} />
              : <Button
                key={typeof action == "string" ? action : action.type}
                fillMode="flat"
                className="btn-action"
                onClick={() =>
                  enter(typeof action == "string" ? action : action.type)
                }
                themeColor={getColor(
                  typeof action == "string" ? action : action.type
                )}
                icon={getIcon(typeof action == "string" ? action : action.type)}
                title={getActionTitle(action)}
              />
            }
          )}

        {modal_name == "delete_" + modelName + "_" + props.dataItem.id && (
          <DeleteModal
            title={"מחק " + title + " " + props.dataItem.id}
            close={closeDialog}
            delete={deleteRow}
          />
        )}
        
      
        {(modal_name && modal_name.split('|||')[0] === ('status_' + modelName +  props.dataItem.id)) && <DeleteModal
                title={title +" שינוי סטטוס"}
          text={'האם אתה בטוח שברצונך להעביר לסטטוס ' + JSON.parse(modal_name.split('|||')[1]).status + '?'}
                close={closeDialog}
          delete={updateStatus}
          delText={'המשך'}
        />}

        {(modal_name && modal_name === ('is_active_' + modelName +  props.dataItem.id)) && <DeleteModal
                title={"שינוי סטטוס ללא פעיל"}
                text='נתון ימחק מכל הרשומות במערכת ללא יכולת שחזור, האם ברצונך להמשיך?'
                close={closeDialog}
                delete={updateToIsNotActive}
        />}
      </td>
    );
  };

  if (!columns) {
    return (
            <div className='loader' >
                <Loader themeColor="dark" type="converging-spinner" size='large'/>
            </div>
        )
  }


  return (
    <div>
      
         {modal_name == "delete_" + modelName + "_multi" && (
          <DeleteModal
            title={"מחק רשומות שנבחרו"}
            close={closeDialog}
            delete={deleteAll}
          />
      )}
      
      {(modal_name == "create_" + props.model ||
      (props.moreActions && props.moreActions.map(act => 'create_' + act.link).includes(modal_name)) ||
        (modal_name &&
          modal_name.split("_")[0] == "edit" &&
          modal_name.split("_")[1] == modelName.split('_')[0])) && (
        <Dialog
          dir="rtl"
          style={{ zIndex: 100 }}
          onClose={closeDialog}
          title={
            currentItem && currentItem.id
              ? currentItem.title +
                " " +
                (currentItem.id ? currentItem.id : "") +
                (currentItem.feildsData.Name
                  ? " / " + currentItem.feildsData.Name
                  : "")
              : btnTxt
          }
        >
          {currentItem?.feildsData ? (
            <EditForm
              model={modelName}
              mode={modal_name.split("_")[0]}
              close={closeDialog}
              isParent={props.isParent}
              id={modal_name.split("_")[modal_name.split('_').length - 1]}
              section={0}
            />
          ) : (
            <div className="loader">
              <Loader
                themeColor="dark"
                type="converging-spinner"
                size="large"
              />
            </div>
          )}
        </Dialog>
      )}
      {sidepopup && <Side model={modelName} onClosePopup={closePopup} />}
      <ExcelExport
        data={model?.data?.rows && Object.values(selectedState).filter(i => i).length
          ? model.data.rows?.filter(row => selectedState[row.id]) : model?.data?.rows || []}
        ref={_export}
        fileName={props.title}
      >
              {props.columns.filter(col => typeof col == "string" || !col.noExport).map((col, i) => (
              <ExcelExportColumn
                key={i}
                cell={col !== "id" && i == 0 && typeof col == 'string' ? false : myCell}
                field={typeof col == "string" ? col : col.field}
                  title={getTitle(col)}
                  cellOptions={{wrap: true}}
                children={
                  typeof col == "object" && col.children
                    ? col.children.map((c) => {
                      return { ...c, }
                      })
                    : null
                }
              />
            ))}
      
      </ExcelExport>

        <LocalizationProvider language={language}>
          <KendoGrid
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            selectedField="selected"
            onRowClick={clickRow}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            data={
              model?.data?.rows
                ? model.data.rows.map((item) => {
                  if (selectedAll && !selectedState[item.id]) {
                    selectedState[item.id] = true;
                    setSelectedState(selectedState)
                  }
                  return {
                    ...item,
                    selected: item.id && (selctedRowId == item.id || selectedState[idGetter(item)] || selectedAll),
                  }
                  })
                : []
            }
            total={model?.data ? parseInt(model.data.count) : 0}
            skip={((mPage -1) * mtake)}
            
            pageSize={mtake}
            pageable={state.pageable}
            onPageChange={changePageSize}
            sortable={!actions?.includes('no-sort') || true}
            sort={actions?.includes('no-sort')  ? false : [{ field: sortBy ? sortBy.replace('-', '') : "id", dir: sortBy ? (sortBy.indexOf('-') === 0 ? 'desc' : 'asc') :  "desc" }]}
            editField="inEdit"
            rowRender={rowRender}
            onSortChange={pageSort}
            className={!!sidePopup ? "slide-popup-grid" : ""}
            ref={_grid}
       /*     style={{
              maxHeight: props.maxHeight || "70vh",
            }}*/
          >
            {actions && (actions.includes("create") || props.moreActions || !actions?.includes("no-export")) && <GridToolbar className="toolGrid">
              {actions && actions.includes("create") ? (
                <Button
                  className="create-btn"
                  onClick={CreateBtn}
                  themeColor={"success"}
                  icon={props.section?.modelIcon || "plus"}
                >
                  {btnTxt}
                </Button>
              ) : null}

              {props.moreActions && props.moreActions.map(act => (
                <Button
                  className="create-btn"
                  onClick={() => BtnGeneric(act)}
                  themeColor={act.color ||"success"}
                  icon={act.icon || "plus"}              >
                  {act.title}
                </Button>
              ))}

              {actions?.includes("delete") && !!Object.values(selectedState).filter(s => s).length &&  <Button
                title="מחיקת רשומות נבחרות"
                className="k-button k-button-md k-rounded-md k-button-solid"
                onClick={() => dispatch(open("delete_" + modelName + "_multi"))}
                themeColor={"secondary"}
                fillMode={'outline'}
                icon={"delete"}
              >
                מחיקה
                  </Button>}
                  
                  {!!Object.values(selectedState).filter(s => s).length && columns.filter(c => c.updateMulti).length ?
                    <div className="edit-tool-grid">
                      {columns.filter(c => c.updateMulti).map(c => {
                      
                        switch (c?.type) {
                          case "boolean":
                            return (<BaseInput data={{ ...c, type: "checkbox" }} onChange={ChangeMulti} />);
                          case "number":
                          case 'text':
                            return (<BaseInput data={{ ...c, type: c?.type, isInline: true }} onBlur={ChangeMulti} />);
                          
                          case 'link':
                            return (<BaseInput data={{ ...c, type: 'text', isInline: true }} onBlur={ChangeMulti} />);
                          case "select":
                            return (<BaseInput data={{ ...c, type: 'select', options: c.list }} onChange={ChangeMulti} />);
                          case "multiselect":
                            return (<div className="multi-input"><BaseInput data={{ ...c, type: 'multiselect', label: c.placeholder ,placeholder: '', options: c.list }} onChange={ChangeMulti} /></div>);
                          default:
                            return;
                        }
                      })
                      }
                      </div>
                : null}
           
              
             {(!!model?.data?.rows?.length && model?.data?.count > model?.data?.rows?.length && (model?.data?.rows?.findIndex((item) => !selectedState[idGetter(item)]) === -1) ||  selectedAll) &&
                <div className="center">
                  {selectedAll ? 
                    <span>כל השורות נבחרו. <a className="pr-10 grid-link" onClick={() => setSelectedAll(false)}>נקה בחירה</a></span>
                    : <span>רק {Math.min(state.pageSize, model?.data?.count)} השורות שבדף נבחרו. <a className="pr-10 grid-link" onClick={() => setSelectedAll(true)}>בחר הכל</a></span>
                     
                } 
              </div>}

              {liveActions.includes(modelName) && <Loader themeColor="dark" style={{ position: 'absolute', right: '50%', zIndex: 100 }} />}
             
              {!actions?.includes("no-export") && !!model?.data?.rows?.length &&  <Button
                title="יצוא לאקסל"
                className="k-button k-button-md k-rounded-md k-button-solid excel-btn"
                onClick={fullExport}
                themeColor={"secondary"}
                fillMode={'flat'}
                icon={"excel"}
              >
                יצוא לאקסל
              </Button>}
            </GridToolbar>}
             <Column
              field={'selected'}
                width="50px"
                headerSelectionValue={
                 selectedAll || !!model?.data?.rows?.length && model?.data?.rows?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                }
              />
            {props.columns.filter(col => typeof col == "string" || col.type != 'disabled').map((col, i) => (
              <Column
                key={i}
                cell={col !== "id" && i == 0 && typeof col == 'string' ? false : myCell}
                field={typeof col == "string" ? col : col.field}
                editable={
                  typeof col == "object" && !!col.type && col.type != "link"
                }
                noExport={typeof col == "string" ? false : col.noExport}
            
                editor={getEditor(col)}
                width={getWidthCell(col)}
                title={getTitle(col)}
                children={
                  typeof col == "object" && col.children
                    ? col.children.map((c) => {
                      return { ...c, }//...{ width: 120 } };
                      })
                    : null
                }
              />
            ))}
            {props.actions &&
            props.actions.filter(
              (a) => a != "create" && a != "view" && a != "client-export" && a != 'no-export' && a != 'no-sort'
            ).length ? (
              <Column
                cell={ActionsCell}
                title="פעולות"
                width={ Math.max(70, props.actions.filter(
              (a) => a != "create" && a != "view" && a != "client-export"
            ).length * 50)}
              />
            ) : null}
              </KendoGrid>
        </LocalizationProvider>
    </div>
  );
}

export default Grid;
