import {FRESH_NOTIFICATIONS } from './types'

export const setFresh = data => {
  return {
    type: FRESH_NOTIFICATIONS,
    payload: data
  }
}


