import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { sendOtp, login, restartOtp } from "../../../redux/actions/authActions";
import { Loader } from "@progress/kendo-react-indicators";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../../assets/images/logo.svg";
import logoIcon from '../../../assets/images/logo_icon.svg';

import "./Login.css";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";

const Login = () => {
  const { otp } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [userName, setUserName] = React.useState(null);
  const [token, setToken] = useState(null);
  const { loading } = useSelector((state) => state.async);
  const recaptchaRef = React.useRef();

  const handleSubmit = (dataItem) => {
   
    if (!otp ) {
      setUserName(dataItem);
      recaptchaRef.current.executeAsync().then(dtoken => {
       
        dispatch(sendOtp({ ...dataItem, recaptcha: dtoken }));
      })
    } else {
      dispatch(login(dataItem));
    }
  };

  const CaptchaChange = (token) => {
    setToken(token);
   
  };
  const refreshOtp = () => {
    window.location.reload();
    // dispatch(restartOtp()); 
    // setTimeout(() => {
    //   console.log(recaptchaRef);
    //   recaptchaRef.current.reset();
    // }, 5000);
  };

  
  if (otp === 'fresh') {
    refreshOtp()
  }

  return (
    <Card className={"login-layout"}>
      <div className="div-logo">
        {" "}
        <img src={logoIcon} className="logo-icon-login" />
        <img src={logo} alt="logo" className="clogo" />
      </div>
      <h2 className="text-center login_title">התחברות</h2>

      <div className={"centered log-form"}>
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement dir={"rtl"} className={"login-form"}>
              {!otp || otp === 'fresh' ? (
                <div className="mb-3">
                  <Field
                    name={"UserName"}
                    autoFocus 
                    component={Input}
                    placeholder={"שם משתמש"}
                    className="login-input"
                    validator={(val) => !!val ? '': 'שדה חובה'}
                  />
                  <Field
                    name={"Password"}
                    component={Input}
                    className="login-input"
                    placeholder={"סיסמא"}
                    validator={(val) => !!val ? '': 'שדה חובה'}
                  />
                  
                  <ReCAPTCHA
                  size="invisible"
                    ref={recaptchaRef}
                    className="captcha-st"
                    sitekey={process.env.CAPTCHA_SITE_KEY || '6LcrBX0nAAAAAIFi7kTukrVV0zFZAdtxK56x010M'} 
                    onChange={CaptchaChange}
                  />
                </div>
              ) : (
                ""
              )}
              {otp && otp != 'fresh' ? (
                <div className="password">
                  <Field
                    name={"Otp"}
                    autoFocus 
                    component={Input}
                    className="login-input"
                    placeholder={"אנא הזן סיסמא שנשלחה בSMS"}
                  />
                  <div className="restert" onClick={refreshOtp}>
                    לא קיבלתי קוד, שלח שוב
                  </div>
                </div>
              ) : (
                ""
              )}

              <footer className="footer">
                <div className="k-form-buttons login_btn">
                  <button
                    type={"submit"}
                    className="k-button k-button-md k-rounded-md k-button-solid login-btn"
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {loading && (
                      <div className="loader_center">
                        <Loader themeColor="dark" size="medium" />
                      </div>
                    )}
                    {"התחבר"}
                  </button>
                </div>
              </footer>
            </FormElement>
          )}
        />
      </div>
    </Card>
  );
};

export default Login;
