import axios from 'axios'
import {asyncActionStart, asyncActionFinish, asyncActionError} from './asyncActions'
import setAuthToken from '../../utils/auth/setAuthToken'
import { getIndex } from './baseDataActions'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_AS_AUTHENTICATED,
  SET_PROFILE_ITEMS,
  LOGOUT,
  USER_DATA,
  SET_ITEMS,
  OTP,
  RESTART_OTP
} from './types'

// Login User
// export const sendOtp=(data)=> 
export const sendOtp = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/send-otp/`

    const res = await axios.post(url, formData)
    if(res.data.data.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else {
      dispatch({
        type: OTP,      
      })
      dispatch(asyncActionFinish())
   
          // In order to test, clean token from localStorage
          // loadUser(); // Gets user from the backend
         
          
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    dispatch({
    type: LOGIN_FAIL
    })
    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const login = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${process.env.REACT_APP_API_URL}/user/login/`

    const res = await axios.post(url, formData)
    if(res.data.data.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else {
      console.log(res.data.data)
        dispatch(setAsAuthenticated(res.data.data.access_token))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data
          })
          
          let items = res.data.data.MenuItems.filter(item => item.Parent === null)

          res.data.data.MenuItems.filter(item => item.Parent !== null).forEach(item => {
            let parentIndex = items.findIndex(i => i.id == item.Parent);
            let children = items.slice(parentIndex + 1).filter(i => i.Parent == item.Parent).length;
            items.splice(parentIndex + 1 + children, 0 , item)
          });

          dispatch({
            type: SET_ITEMS,
            payload: items
          })
          // In order to test, clean token from localStorage
          // loadUser(); // Gets user from the backend
         
          dispatch(getIndex({name: 'home'}))

          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    })
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const setAsAuthenticated = authToken => {
  localStorage.setItem("AuthToken", authToken);
  setAuthToken(authToken)
  return {type: SET_AS_AUTHENTICATED, payload: authToken}
}

export const setProfileItem = (Name, NumUsedHearts, NumValidHearts) => {
  return {type: SET_PROFILE_ITEMS, payload: {Name, NumUsedHearts, NumValidHearts}}
}

export const getMeDetails = () => async dispatch => {
  dispatch(asyncActionStart())
  try {   

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/me/`)
    if(res.data.data.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data
          })
          
          let items = res.data.data.MenuItems.filter(item => item.Parent === null)

          res.data.data.MenuItems.filter(item => item.Parent !== null).forEach(item => {
            let parentIndex = items.findIndex(i => i.id == item.Parent);
            let children = items.slice(parentIndex + 1).filter(i => i.Parent == item.Parent).length;
            items.splice(parentIndex + 1 + children, 0 , item)
          });

          dispatch({
            type: SET_ITEMS,
            payload: items
          })
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError('אנא היכנס מחדש'))

      } else {
    //    dispatch(asyncActionError('Server error'))
      }
    
    }
  }
}
export const restartOtp=()=>{
  return{
    type: RESTART_OTP,
  }
}
// Logout
export const logout = () => {
  setAuthToken(false)
  return {
    type: LOGOUT
  }
}

