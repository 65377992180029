import axios from 'axios'
import {asyncActionStart, asyncActionFinish, asyncActionError} from './asyncActions'
import {
  LOOKUP,
  INDEX,
  DELETE,
  UPDATE,
  UPDATE_FIELDS,
  UPDATE_ROW,
  CREATE,
  CREATE_INLINE,
  VIEW,
  LOG_DATA,
  VIEW_CHILD,
  ADD_LOG_ITEM,
  DELETE_LOG_ITEM,
  UPDATE_LOG_ITEM,
  VALIDATIONֹֹֹ_ERROR,
  AUTO_COMPLATE,
  ENTER_EDIT,
  UPDATE_CURRENT_ITEM_FIELDS,
  START_LOG,
  SHOW_MORE_LOG
} from './types'
import { toast } from 'react-toastify';
import { setFresh } from './NotificationsActions';





export const lookup = (params) => async dispatch => {
    dispatch(asyncActionStart())
    try {
      const url = `${process.env.REACT_APP_API_URL}/lookup/${params.name}`
 
      const res = await axios.get(url)
          dispatch({
              type: LOOKUP,
              payload: {...res.data, model: params.name}
            })   
            dispatch(asyncActionFinish())
      
     
    } catch (error) {
      console.log(error)    
      if (error.response && error.response.data && error.response.data.title) {
        // dispatch(asyncActionError(error.response.data.title))
        dispatch(asyncActionError(error.response.data))
      } else {
        dispatch(asyncActionError('Server error'))
      }
    }
}

export const getCreate = data => {
  return {
    type: data.isParent ? VIEW : VIEW_CHILD,
    payload: data
  }
}

export const getIndex = (params) => async dispatch => {
  dispatch(asyncActionStart(params.name))
  try {
    const urlParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if(params[key] && key != 'name'){
        urlParams.append(key, params[key])
      }
    })

    if (!params['per-page']) {
      urlParams.set('per-page', 100)
    }
    const url = `${process.env.REACT_APP_API_URL}/${params.name}?${urlParams.toString()}`
    const res = await axios.get(url)
        dispatch({
          type: INDEX,
          payload: {
            ...res.data,
            model: params.name,
            sortBy: params.sort,
            page: params.page,
            take: params['per-page'] || 100,
            filters: params
          }
          })   
          dispatch(asyncActionFinish(params.name))
    
   
  } catch (error) {
    console.log(error)
    dispatch({
      type: INDEX,
      payload: {data : {}, model: params.name}
    })       
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
  //    dispatch(asyncActionError(error.response.data))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

export const updateRow = (params) => {
  return {
    type: UPDATE_ROW,
    payload: {
      data: params.data,
      model: params.model,
      isParent: params.isParent,
      field: params.field
    }
  }
}

export const updateItem = (params) => async dispatch => {
  try {
        const form = new FormData()
        Object.keys(params.data).forEach(
          key => params.data[key] === null
            ? form.append(key, '')
            : form.append(key, (
              //typeof params.data[key] == "object" ? JSON.stringify(params.data[key]) : 
              params.data[key]))
    )
        
        const url = `${process.env.REACT_APP_API_URL}/${params.model.split('?')[0]}/${params.id}`
        const res = await axios.put(url, form)
         dispatch({
             type: params.isInline ? UPDATE_ROW : UPDATE,
            payload: {
              data: { ...res.data.data, saved_on_server: true },
              model: params.model,
              isParent:
              params.isParent,
              field: params.field
            }
         })

         const freshNotes = res.headers['x-reload-notes']

         if(freshNotes) {
          
           const fresh = JSON.parse(freshNotes)
        
          if (fresh.model == params.model.split('/')[0]) {
             dispatch(getLog(fresh))
           }
         }

         const freshView = res.headers['x-reload-view']
         if(freshView) {
            dispatch(getView({
              model: JSON.parse(freshView).model,
              id: JSON.parse(freshView).id,
              isParent: JSON.parse(freshView).isParent,
              notClear: JSON.parse(freshView).notClear === undefined ? true : JSON.parse(freshView).notClear
             }))   
             
           
                 
      }
      
        const freshIndex = res.headers['x-reload-index']

         if(freshIndex) {          
           console.log(JSON.parse(freshIndex))
           dispatch(getIndex({
              name: JSON.parse(freshIndex).name,
              sort:  JSON.parse(freshIndex).sortBy || "-id",
              page: 1,
              "per-page": 100,//JSON.parse(freshIndex).isParent ? 100 : 5,
           }))
          
         }




         const message = res.headers['x-message']
         if(message) {
          JSON.parse(message).forEach(m => {
            toast(m, {
              type: 'warning',
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              });
            })

         } else {
          toast.success(`רשומה עודכנה בהצלחה`, {
           position: "top-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            });
    
         }
    } catch (error) {
      console.log(error)
        if (error.response && error.response.data) {
          const message = error.response.headers['x-message']
          if(message) {
           JSON.parse(message).forEach(m => {
             toast(m, {
               type: 'warning',
               position: "top-left",
               autoClose: 3000,
               hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: true,
               });
             })
 
          }
          else if(error.response.data.message) {
             dispatch(asyncActionError(error.response.data.message))
          } else {
            if(error.response.status == 422) {
                dispatch({
                  type: VALIDATIONֹֹֹ_ERROR,
                  payload: error.response.data
                })
              
              
              dispatch(asyncActionError(error.response.data.map(d => d.message).join(', ')))

            }
          }
          //  
        } else {
            dispatch(asyncActionError('Server error'))
        }
    }
}

export const enterEdit = (params) => {
  return {
    type: ENTER_EDIT,
    payload: params
  }
}

export const create = (params) => async dispatch => {
  try {
      dispatch(asyncActionStart())

      const form = new FormData()
      Object.keys(params.data).forEach(
          key => params.data[key] === null ?  form.append(key, '') : form.append(key, params.data[key])
      )

      const url = `${process.env.REACT_APP_API_URL}/${params.model}`
       const res = await axios.post(url, form)
       dispatch({
           type: params.isInline ? CREATE_INLINE : CREATE,
           payload: {data: {...res.data.data, saved_on_server: true}, model: params.model, isParent : params.isParent}
       })
       
       toast.success(`רשומה נשמרה בהצלחה`, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        });

        const freshNotes = res.headers['x-reload-notes']

         if(freshNotes) {
          
           const fresh = JSON.parse(freshNotes)
           if (fresh.model == params.model.split('/')[0]) {
             dispatch(getLog(fresh))
           }
        }
    
        const freshView = res.headers['x-reload-view']

         if(freshView) {
           dispatch(getView({
             model: JSON.parse(freshView).model,
             id: JSON.parse(freshView).id,
             isParent: JSON.parse(freshView).isParent,
             notClear: true
           }))          
      }
    
    const freshIndex = res.headers['x-reload-index']
        if(freshIndex) {          
           
           dispatch(getIndex({
              name: JSON.parse(freshIndex).name,
              sort:  JSON.parse(freshIndex).sortBy || "-Id",
              page: 1,
              "per-page": JSON.parse(freshIndex).isParent ? 100 : 5,
           }))
          
         }


        const message = res.headers['x-message']
        if(message) {
         JSON.parse(message).forEach(m => {
           toast(m, {
             type: 'warning',
             position: "top-left",
             autoClose: 3000,
             hideProgressBar: true,
             closeOnClick: true,
             pauseOnHover: true,
             });
           })

        }

      const freshNotifications = res.headers['x-fresh-notifications']
      if(freshNotifications) {
        dispatch(setFresh(true))
      }

        dispatch(asyncActionFinish())

       
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data) {
      if(error.response.data.message) {
         dispatch(asyncActionError(error.response.data.message))
      } else {
        if(error.response.status == 422) {
            dispatch({
              type: VALIDATIONֹֹֹ_ERROR,
              payload: error.response.data
          })
          dispatch(asyncActionError(error.response.data.map(d => d.message).join(' ')))
        }
      }
      //  
    } else {
        dispatch(asyncActionError('Server error'))
    }
  }
}


export const convert = (params) => async dispatch => {
  try {
      const url = `${process.env.REACT_APP_API_URL}/${params.model}/${params.id}/convert`
       const res = await axios.post(url)
       dispatch({
        type: DELETE,
        payload: params
       })
        dispatch(asyncActionFinish())
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data) {
      if(error.response.status == 422) {
        dispatch({
          type: VALIDATIONֹֹֹ_ERROR,
          payload: error.response.data
      })
      dispatch(asyncActionError("קיימים ערכים לא תקינים"))
    }
      else if(error.response.data.message) {
        console.log(error.response, 'gfdf')
        dispatch(asyncActionError(error.response.data.message))
     } 
       
     
    } else {
        dispatch(asyncActionError('Server error'))
    }
  }
}

export const updateCurrentItemFileds = (data) => {
  return {
    type: UPDATE_CURRENT_ITEM_FIELDS,
    payload: data
  }
}



export const updateFields = (data) => {
  return {
    type: UPDATE_FIELDS,
    payload: data
  }

}

export const deleteItem = (params) => async dispatch => {
  //  dispatch(asyncActionStart())
    try {
      const url = `${process.env.REACT_APP_API_URL}/${params.model}/${params.id}`
      
    var date = new Date();

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

      const res = await axios.put(url, { 'deleted_at' : (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)})
      dispatch({
          type: DELETE,
          payload: params
      })
      
      const freshNotes = res.headers['x-reload-notes']

         if(freshNotes) {
          
           const fresh = JSON.parse(freshNotes)
        
          if (fresh.model == params.model.split('/')[0]) {
             dispatch(getLog(fresh))
           }
         }

         const freshView = res.headers['x-reload-view']
         if(freshView) {
           dispatch(getView({
             model: JSON.parse(freshView).model,
             id: JSON.parse(freshView).id,
             isParent: JSON.parse(freshView).isParent,
             notClear: true
           }))          
      }
      
          const freshIndex = res.headers['x-reload-index']

         if(freshIndex) {          
           console.log(JSON.parse(freshIndex))
           dispatch(getIndex({
              name: JSON.parse(freshIndex).name,
              sort:  JSON.parse(freshIndex).sortBy || "-Id",
              page: 1,
              "per-page": JSON.parse(freshIndex).isParent ? 100 : 5,
           }))
          
         }




         const message = res.headers['x-message']
         if(message) {
          JSON.parse(message).forEach(m => {
            toast(m, {
              type: 'warning',
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              });
            })

         }
//        dispatch(asyncActionFinish())


    } catch (error) {
      console.log(error)
      if (error.response && error.response.data && error.response.data.title) {
        // dispatch(asyncActionError(error.response.data.title))
        dispatch(asyncActionError(error.response.data))
      } else if(error.response && error.response.data?.message) { 
        dispatch(asyncActionError(error.response.data.message))
      
      } else {
        dispatch(asyncActionError('Server error'))
      }
    }
}
  
export const emptyView = (isParent) => {
  return {
      type: isParent ? VIEW : VIEW_CHILD,
      payload: null    
  }
}

  export const getView = (params) => async dispatch => {
    
    if (params.notClear === undefined || !params.notClear) {
      dispatch(asyncActionStart(params.model))
    }
      try {
      
        if (params.notClear === undefined || !params.notClear) {
          dispatch({
            type: params.isParent ? VIEW : VIEW_CHILD,
            payload: null
          })
        }
          const split =  params.model.split('?');
          const model = split[0]
          const queryString = split.length > 1 ? ('?' + split[1]) : ''
     
        const url = `${process.env.REACT_APP_API_URL}/${model}/${params.id !== undefined ? params.id : (params.model.includes('report')  ?  '' : '0')}${queryString}`
        const res = await axios.get(url)
        dispatch({
            type: params.isParent ? VIEW : VIEW_CHILD,
            payload: {...res.data.data, model: params.model, Id: params.id}
          })   
          dispatch(asyncActionFinish(params.model))
        
       
      } catch (error) {
        console.log(error.response)    
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data.message))
        } else if (error.response.status == 404) {
          dispatch(asyncActionError('אובייקט לא נמצא'))
           dispatch({
            type: params.isParent ? VIEW : VIEW_CHILD,
            payload: {error : 404}
          })   
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
    }

    export const getLog = (params) => async dispatch => {
      dispatch(asyncActionStart(params.model))
      try {
    
        const page = params.page || 1;
        const url = `${process.env.REACT_APP_API_URL}/model-log?model=${params.model}&modelId=${params.id}&page=${page}`
        const res = await axios.get(url)
        dispatch({
            type: params.page && params.page > 1 ? SHOW_MORE_LOG : LOG_DATA,
            payload: {...res.data.data, model: params.model, id: params.id}
          })   
          dispatch(asyncActionFinish(params.model))
        
       
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data))
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
}
    

    export const SendWhatsApp = (params) => async dispatch => {
      dispatch(asyncActionStart())
      try {

      
        
        const url = `${process.env.REACT_APP_API_URL}/whatsapp/send`
        const res = await axios.post(url, params)

        const freshNotes = res.headers['x-reload-notes']

        if(freshNotes) {
          
           const fresh = JSON.parse(freshNotes)
        
          if (fresh.model == params.Model.split('/')[0]) {
             dispatch(getLog(fresh))
           }
         }
        dispatch(asyncActionFinish())
        toast.success(`נשלח whatsapp בהצלחה`, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        })

       
      } catch (error) {
        console.log(error)    
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data))
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
    }

    export const CreateLog = (params) => async dispatch => {
      dispatch(asyncActionStart())
      try {

      
        
        const url = `${process.env.REACT_APP_API_URL}/model-log`
        const res = await axios.post(url, params)
        dispatch({
            type: ADD_LOG_ITEM,
            payload: res.data.data
          })   
          dispatch(asyncActionFinish())
        
       
      } catch (error) {
        console.log(error)    
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data))
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
    }

    export const UpdateLog = (params) => async dispatch => {
      dispatch(asyncActionStart())
      try {
       
        const url = `${process.env.REACT_APP_API_URL}/model-log/${params.id}`
        const res = await axios.put(url, params)
        
        dispatch({
            type: params.Deleted ? DELETE_LOG_ITEM : UPDATE_LOG_ITEM,
            payload: params.Deleted ? params : res.data.data
          })   
          dispatch(asyncActionFinish())
        
       
      } catch (error) {
        console.log(error)    
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data))
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
}
    
  export const startLog = (text) => {
    return {
        type: START_LOG,
        payload:  text
   }   
        
  }

    export const autocomplate = (params) =>  async dispatch => {
    
      try {
       /* dispatch({
          type: AUTO_COMPLATE,
          payload: []
        })*/   
        const url = `${process.env.REACT_APP_API_URL}/suggestions/${params.model}/?q=${params.term}`
        const res = await axios.get(url)
        
        dispatch({
            type: AUTO_COMPLATE,
            payload: res.data
          }) 
       
      } catch (error) {
        console.log(error)    
        if (error.response && error.response.data && error.response.data.message) {
          // dispatch(asyncActionError(error.response.data.title))
          dispatch(asyncActionError(error.response.data))
        } else {
          dispatch(asyncActionError('Server error'))
        }
      }
    }

export const UpdateMutliItems = (params) => async dispatch => {
      console.log(params)
    try {
      var res = '';
      if (params.isParent) {
        const url = `${process.env.REACT_APP_API_URL}/${params.model}/multi/`
        res = await axios.put(url, params.data)
      } else {
        const url = `${process.env.REACT_APP_API_URL}/${params.model}/${params.data.ids[0]}`
        res = await axios.put(url, params.data)
        
      }

      const freshNotifications = res.headers['x-fresh-notifications']
      if(freshNotifications) {
        dispatch(setFresh(true))
      }

        const freshNotes = res.headers['x-reload-notes']

        if(freshNotes) {
          
           const fresh = JSON.parse(freshNotes)
        
          if (fresh.model == params.model.split('/')[0]) {
             dispatch(getLog(fresh))
           }
         }

         const freshView = res.headers['x-reload-view']
         if(freshView) {
           dispatch(getView({
             model: JSON.parse(freshView).model,
             id: JSON.parse(freshView).id,
             isParent: JSON.parse(freshView).isParent,
             notClear: true,//JSON.parse(freshView).notClear === undefined ? true : JSON.parse(freshView).notClear
           }))          
        }

      const freshIndex = res.headers['x-reload-index']

         if(freshIndex) {          
           console.log(JSON.parse(freshIndex))
           dispatch(getIndex({
              name: JSON.parse(freshIndex).name,
              sort:  JSON.parse(freshIndex).sortBy || "-id",
              page: 1,
              "per-page": JSON.parse(freshIndex).isParent ? 100 : 5,
           }))
          
         }

      
        dispatch(getIndex(params.fresh))
        
         const message = res.headers['x-message']
         if(message) {
          JSON.parse(message).forEach(m => {
            toast(m, {
              type: 'warning',
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              });
            })

         } else {
          toast.success(`רשומה עודכנה בהצלחה`, {
           position: "top-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            });
    
         }
    } catch (error) {
      console.log(error)
        if (error.response && error.response.data) {
          const message = error.response.headers['x-message']
          if(message) {
           JSON.parse(message).forEach(m => {
             toast(m, {
               type: 'warning',
               position: "top-left",
               autoClose: 3000,
               hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: true,
               });
             })
 
          }
          else if(error.response.data.message) {
             dispatch(asyncActionError(error.response.data.message))
          } else {
            if(error.response.status == 422) {
                dispatch({
                  type: VALIDATIONֹֹֹ_ERROR,
                  payload: error.response.data
                })
              
              
              dispatch(asyncActionError(error.response.data.map(d => d.message).join(', ')))

            }
          }
          //  
        } else if(error.response.status != 401) {
            dispatch(asyncActionError('Server error'))
        }
    }
}