import {SET_NAVBAR_CURRENT_ITEM, SET_EXPPANDED_NAV, SET_ITEMS} from './types'

export const setNavbarCurrentItem = activeItem => {
  return {
    type: SET_NAVBAR_CURRENT_ITEM,
    payload: activeItem
  }
}

export const setExppandedNav = isOpen => {
  return {
    type: SET_EXPPANDED_NAV,
    payload: isOpen
  }
}

export const setItems = items => {
  return {
    type: SET_ITEMS,
    payload: items
  }
}


