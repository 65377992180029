import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./Retro.css";
import { Button } from "@progress/kendo-react-buttons";
import Grid from "../../layout/grid/Grid";
import { useDispatch, useSelector } from "react-redux";
import { close, open } from "../../../redux/actions/modalActions";
import DeleteModal from "../../layout/deleteModal/DeleteModal";
import { updateItem, getIndex, updateRow } from "../../../redux/actions/baseDataActions";
import { $CombinedState } from "redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { asyncActionError } from "../../../redux/actions/asyncActions";


const Retro = (props) => {

    const location = useLocation()
    const dispatch = useDispatch()
    const { modal_name } = useSelector((state) => state.modal);
    
    const sort = props.sort;
    const model = "clients/{id}/retro".replace('{id}', props.data.ClientId)
    const base = useSelector(state => state.base)
    if (model && base[model]) {
        var modelData = base[model]?.data?.rows
    

    }
    const [confirmModal, setConfirmNodal] = useState(false) 
    const Open = () => {
        dispatch(getIndex({
          name: model,
          sort: "-Id",
          page: 1,
          "per-page": 100,
        }))

        setTimeout(() => {
            dispatch(open('retro_' + props.data.ClientId))
        
        }, 100);
    }
    const Close = () => {
      
        dispatch(close());
         dispatch(getIndex({
                ...Object.fromEntries((new URLSearchParams(location.hash.replace('#', '')))),
                name: 'budget-confirms',
            }))
    }


    const Send = async () => {
        if (!modelData) {
            return
        }
        try {
            const ids = modelData.filter(r => r.isPaid).map(r => r.Id)  
            const res = await axios.post(process.env.REACT_APP_API + '/v1/clients/' + props.data.ClientId + '/retro', { Ids: ids })
            
            dispatch(getIndex({
                ...Object.fromEntries((new URLSearchParams(location.hash.replace('#', '')))),
                name: 'budget-confirms',
            }))

            dispatch(close())

        }
        catch(err) {
            console.log(err)
            let msg = err.response?.data?.message || 'חלה שגיאה כללית'
            dispatch(asyncActionError(msg))
        }
         
    }

    const CellChanged = (dataItem, field, value) => {
    
        dispatch(updateRow({
            data: { ...dataItem, 'isPaid': value },
            field: "isPaid",
            model: model,
            isParent: false,
        }))
    }

    const columns = [
        {
            'title': 'שנה',
            field: 'Year',
        },
        {
            title: 'חודש',
            field : 'Month'
        },
        {
            title: 'מספר ימים',
            field : 'DaysNo'
        },
        {
            title: 'בוצע תשלום',
            field: 'isPaid',
            type: 'boolean',
   //         confirm: 'confirm_retro_' + props.data.ClientId
        },
    ]
    
    return (
        <>
            {props.data.RetroNo && <Button fillMode={"outline"} title={"מעקב אישורים חסרים"}  onClick={Open} >
                {props.data.RetroNo || ''}
            </Button>}
            {(modal_name == 'retro_' +  props.data.ClientId) && <Dialog title={'מעקב אישורים חסרים / ' +   props.data.ClientName} onClose={Close} dir="rtl" >
                <div style={{maxWidth: '500px'}} >
                    <Grid model={model} isParent={false}
                        cellChanged={CellChanged}
                        pageable={false}
                        actions={['no-export', 'delete-without-confirm']}
                        columns={columns} />
                    <div className="k-form-buttons">
                    
              
                          <Button type={"button"}  onClick={() =>  dispatch(close())}  fillMode="flat">ביטול</Button>            
                          <Button onClick={() => setConfirmNodal(true)} themeColor={'info'}  style={{marginLeft: '30px'}}>שמירה</Button>
              
                        </div>
                </div>
            </Dialog>
            }
            {(modal_name && (modal_name == 'retro_' +  props.data.ClientId) && confirmModal) && <DeleteModal
                title={"ביצוע תשלום"}
                text='בטוח שברצונך להמשיך?'
                delText={'אישור'}
                close={() => { setConfirmNodal(false)}}
                delete={Send}
            />}
        </>
  );
};

export default Retro;
