import {
    ASYNC_ACTION_START,
    ASYNC_ACTION_FINISH,
    ASYNC_ACTION_ERROR,
    ASYNC_ACTION_CLEAN_ERROR
  } from './types';
  import { toast } from 'react-toastify';

  import 'react-toastify/dist/ReactToastify.css';
  
  export const asyncActionStart = (payload) => {
  
    return {
      type: ASYNC_ACTION_START,
      payload: payload
    };
  };
  
  export const asyncActionFinish = (payload) => {
    return {
      type: ASYNC_ACTION_FINISH,
      payload: payload
    };
  };
  
  export const asyncActionError = (error) => {
    console.log(error)
    toast.error(error === "Server error" ? "חלה שגיאה כללית" : (typeof error == 'string' ? error : error.join(', ')), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      pauseOnHover: true,
      });
    return {
      type: ASYNC_ACTION_ERROR,
      payload: error,
    };
  };
  
  export const asyncActionCleanError = () => {
    return {
      type: ASYNC_ACTION_CLEAN_ERROR
    };
  };
  