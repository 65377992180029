import React from 'react'
import './Title.css'

const Title = (props) => {

 return (
    <div className='title_section'>

        <h2> {props.title} </h2>    
          {props.subTitle&& <h5>{props.subTitle}</h5>} 
    </div>
  )
}

export default Title
