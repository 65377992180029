import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch, useSelector } from "react-redux";
import { close, open } from "../../redux/actions/modalActions";
import axios from "axios";
import { getIndex } from "../../redux/actions/baseDataActions";


const SendLead = (props) => {
    const title = props.withCi ? 'שליחה כולל CI' : 'שליחה ללא CI';
    const dispatch = useDispatch()
    const { modal_name } = useSelector((state) => state.modal);
    
    const Open = () => {
        dispatch(open('lead_partner_'  + (props.withCi ? '1' : '0') + '_' + props.data.Id))
    }
    
    const Close = () => {
        dispatch(close());
    }

    const Send = () => {
         const url = `${process.env.REACT_APP_API_URL}/leads/${props.data.lead_id}/send-to-partner`
       
        axios.post(url, { ...props.data, 
            withCi: props.withCi, 
            Id : props.data.partner_id || props.data.Id 
        }).then(({ data }) => {
            
            if(props.fresh) {
                props.fresh()
            } else {
                dispatch(getIndex({
                    name: `leads/${props.data.lead_id}/partners`,
                        page: 1,
                        "per-page": 100,
        
                }))
            }
             dispatch(close());
        })
    }

    const partnerName = props.data?.partner_name ? props.data?.partner_name : props?.data?.name;
   
    
    return (
        <>
            <Button fillMode={'flat'} title={title} onClick={Open} icon={props.withCi
                ? " fa-solid fa-plane-departure"
                : " fa-regular fa-paper-plane"} >
              
            </Button>
            {(modal_name == ('lead_partner_'  + (props.withCi ? '1' : '0') + '_' + props.data.Id)) &&
                <Dialog title={title + " - " + partnerName} onClose={Close} dir="rtl" style={{ zIndex: 100 }}> 
                       <p
                        style={{
                        margin: "25px",
                        textAlign: "center",
                        }}
                    >
               בטוח שאתה רוצה לשלוח ל{partnerName}?
                    </p>
                
                    <div className="actions_delete">
                            <Button fillMode={"flat"} onClick={Close} style={{ margin: '0 5px'}}>
                            ביטול
                            </Button>
                            <Button  className="delete_btn" themeColor={"warning"} onClick={Send}>
                                {title}
                            </Button>
                    </div>    
                 
                </Dialog>
            }
    </>
  );
};

export default SendLead;
