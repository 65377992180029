import {Provider} from 'react-redux' // redux
import store from './redux/store/store' // Redux
import {BrowserRouter as Router} from 'react-router-dom'
import Content from './components/content/Content'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@progress/kendo-theme-default/dist/all.css';
import './App.css'

function App() {
  return (
    <Router>
      <Provider store={store}>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"
      />
        <Content />
        <ToastContainer/>
      </Provider>
    </Router>
  )
}

export default App
