import {combineReducers} from 'redux'
import authReducer from './authReducer'
import baseDataReducer from './baseDataReducer'
import navbarReducer from './navbarReducer'
import asyncReducer from './asyncReducer'
import modalReducer from './modalReducer'
import notificationsReducer from './NotificationsReducer'



const rootReducer = combineReducers({
  auth: authReducer,
  base: baseDataReducer,
  navbar: navbarReducer,
  async: asyncReducer,
  modal: modalReducer,
  notifications: notificationsReducer

})

export default rootReducer
