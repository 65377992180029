
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    Form,
    FormElement,
    FormRenderProps,
  } from "@progress/kendo-react-form";
import BaseInput from "../baseInput/BaseInput";
import { useDispatch, useSelector } from "react-redux";

import Title from "../title/Title";
import axios from "axios";

import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
    
import './ComboPopup.css'


const ComboPopup = (props) => {

    const { loading } = useSelector((state) => state.async);
  const [data, SetData] = useState(props.form.data)
    const closeDialog = () => {
     
        props.close()
    }

    const UnChange = (e, value) => {
        data[e.fieldName] = value;   
        SetData(data)
    }

  const submit = () => {
      if (!props.form.url)
      {
        if (props.addToParent) {
          props.addToParent(data)
        }
        
        return;
      }
        axios.post(`${process.env.REACT_APP_API_URL}/${props.form.url}`, data)
          .then(({ data }) => {
            props.close()
          })
    }

    return (
        <Dialog dir="rtl" width={'360px'} style={{ zIndex: 100}} title={props.form.title || ''}  onClose={closeDialog}>
        <section className='content'>
            <Form
                render={(formRenderProps : FormRenderProps) => (                
                    <FormElement style={{
                  maxWidth: '100%',
                      width: '360px'
                    }}>
                        <fieldset className={"k-form-fieldset"}>                          
                            <div className="form-content">
                            {props.form.fields ? props.form.fields.filter(feild => !feild.readOnly).map((field, i) => (
                            <div className="mb-3 edit-input width-100" >                                
                                <BaseInput   onChange={UnChange} data={{...field,formRenderProps:formRenderProps, model: props.model, value: undefined }}/>
                            </div>
                            )) : null}</div>
                        </fieldset>

             

            <div className="k-form-buttons">

            <Button
                type={"button"}
                fillMode="flat"
                onClick={closeDialog}
              >
                ביטול
              </Button>
              
              <Button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base submit-btn"
                onClick={submit} 
                themeColor="warning"
              >
                יצירה
                {loading && <Loader themeColor="dark" size="small" />}
              </Button>

           
            </div>           
          </FormElement>
        )}
      />
    </section>
        </Dialog>
    )
}

export default ComboPopup