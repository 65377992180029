import {FRESH_NOTIFICATIONS} from '../actions/types'

const initialState = {
  fresh: null,
}

const notificationsReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {    
    case FRESH_NOTIFICATIONS:
     
      return {
        ...state,
        fresh: payload
      }
    default:
      return state
  }
}

export default notificationsReducer
