import {useEffect, useState} from 'react'
import {Route, Switch, Routes, Navigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import PrivateUserRoute from '../../routing/PrivateUserRoute'
import Header from '../layout/header/Header'
import Login from '../auth/login/Login'
import {setAsAuthenticated, getMeDetails} from '../../redux/actions/authActions'
import './Content.css'
import Menu from '../layout/menu/Menu'
import axios from 'axios';
import AppLogout from '../../utils/auth/AppLogout'

function Content() {

  
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isAuthenticated && localStorage.getItem('AuthToken') ) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }  
  }, [])

  if(!isAuthenticated) {
    return (
      <div>
        <Header />
        <Login/>
      </div> 
    )
  }
  

  return (
      <div className='content_page'>
      {/* <Header /> */}
          <AppLogout>
             <Menu /> 
        </AppLogout>
    </div>
  )
}

export default Content
