import React, { useEffect, useState } from "react";
import {
  Input,
  Checkbox,
  ColorPicker,
  TextArea,
} from "@progress/kendo-react-inputs";
import {
  DropDownList,
  MultiSelect,
  ComboBox,
} from "@progress/kendo-react-dropdowns";
import { useForm } from "react-hook-form";
import {
  DateRangePicker,
  DatePicker,
  TimePicker,
  Calendar
} from "@progress/kendo-react-dateinputs";
import { Upload } from "@progress/kendo-react-upload";
import {
  FloatingLabel,
  Label,
  Hint,
  Error,
} from "@progress/kendo-react-labels";
import { autocomplate } from "../../../redux/actions/baseDataActions";
import Autocomplete from "react-google-autocomplete";

import "./BaseInput.css";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ComboPopup from "./ComboPopup";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Button } from "@progress/kendo-react-buttons";
import { Editor, EditorTools, ProseMirror } from "@progress/kendo-react-editor";
import { filterBy } from "@progress/kendo-data-query";

import {
  ListView,
} from "@progress/kendo-react-listview";

import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";
import List from "./List";
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  Link,
  Unlink,
} = EditorTools;



const MonthsCalendar = (props) => {
  return  (
    <Calendar
      bottomView="year"
      topView="year"
      value={props.value}
      onChange={props.onChange}
    />
  );

}
  
const GenericInput = (props) => {
  const { AuthToken } = useSelector((state) => state.auth);
  const [fvalue, setValue] = useState(props.data.value);
  const [fileValue, setFileValue] = useState();
  const [fileName, setFileName] = useState(props.data.value&&props.data.value.name?props.data.value.name:props.data.value);
  const [ddlData, setDdlData] = useState([...[{name: '-ללא בחירה-', value: null, id: null}], ...props.data.options || []])
  const [multiData, setMultiData] = useState(props.data.options || [])

  const [ddlCopyTitle, setDdlCopyTitle] = useState('העתק נתונים');
  const [loading, setLoading] = useState(false);
  const [acdata, setAcdata] = useState(props.data.options || []);
  const [filterValue, setFilterValue] = useState(null)
  const loadTreeItems = (items) => {
    return items.map(item => {
                    item.checked = !!fvalue[item.id]
                    if (item.items) {
                      item.items = item.items.map(child => { 
                        return {
                          ...child,
                          checked : !!fvalue[child.id]
                        }
                      })
                    }
                    return item
                  })
  }
  const [treeItems, setTreeItems] = useState(props.data.items ? loadTreeItems(props.data.items) : []);
  //const [listData, setListData] = useState(fvalue)
  const dispatch = useDispatch();

  useEffect(() => {
    
    if (props.data.type == 'treeview') {
     setTreeItems(loadTreeItems(props.data.items))
    }
    
     if (fvalue !== undefined && props.onChange && props.data.type != 'file') {
       props.onChange(props.data, fvalue, false);
    }
    
 }, [fvalue])

  
  useEffect(() => {
      setValue(props.data.value)
     
    
 }, [props.data.value])

  const { register, handleSubmit } = useForm();
    var { currentItem } = useSelector((state) => state.base);
  const errorValidation = currentItem?.errorValidation;
  const [openPopup, setOpenPopup] = useState(false)
  if(props.data.clear && fvalue) {
    setValue('')
  }
const hiddenFileInput = React.useRef(null);

  const pad = (num) => {
    return (num < 10 ? '0' : '') + num;
  }

  const Blur = (e) => {
    if (props.onBlur) {
      let value = e.target.value;
      
      switch (props.data.type) {
        case 'editor':
          value = fvalue && !e.target.htmlOnChange ? fvalue : e.target.htmlOnChange;
          
        default:
          break;
      }
    
      props.onBlur(props.data, value)
    }
  }

  const AddToParent = (data) => {
    setOpenPopup(false)
    setValue({ id: -1, label: 'נוצר אובייקט חדש' });

    if (props.onChange) {
      props.onChange(props.data, JSON.stringify(data));
    }
  }

  
  const listNoDataRender = () => {
    
    if (!filterValue) {
      return ('הזן טקטס לחיפוש')
    }

    if (loading) {
      return 'אנא המתן...'
    }
  return (
  <h4
    style={{
      fontSize: "1em",
      textAlign: 'center'
    }}
  >
    <span
      className="k-icon k-i-warning"
      style={{
        fontSize: "2.5em",
      }}
    />
    <br />
    <br />
    אין נתונים
  </h4>
)
  };
  
  const getCurrentYear = (year) => {
    switch (year) {
      case 1:
        return '1000';
      case 2:
        return '2000';
      default: 
        return year
    }
  }

  const ChangeMap = (place) => {
    if(!place) {
      return;
    }

    const addressComponents = place.address_components;
    let street            = "";
    let number  = "";
    let locality = '';

    // loop through addressComponents and find the route and street_number types
    for (let i = 0, i_len = addressComponents.length; i < i_len; i++) {
        let addressType = addressComponents[i].types[0];

        switch(addressType) {
            case "route":
                street = addressComponents[i]["long_name"];
                break;

            case "street_number":
                number = addressComponents[i]["long_name"];
                break;
            case 'locality':
              locality = addressComponents[i]['long_name'];
              break;
        }
    }
    
    let value = {
      adress : street +" " + number + ", " + locality ,
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),

    }

    if (props.onChange ) {
      props.onChange(props.data, value);
    }
  

  }
    

  const Change = (e) => {
 
    let value = e.target.value;
    switch (props.data.type) {
      case "date":
        value = value ? getCurrentYear(value.getFullYear()) + '-' + pad(value.getMonth() + 1) +
            '-' + pad(value.getDate()) : "";
        

        setValue(e.target.value);
        break;
      case "time":
        /*value = value
          ? (value.getHours() < 10 ? "0" : "") +
            value.getHours() +
            ":" +
            (value.getMinutes() < 10 ? "0" : "") +
            value.getMinutes()
          : "";*/
        value = value.value ? value.value : value.id;
          setValue(value);
        break;
      case "select":
        
        value = value.value !== undefined ? value.value : value.id;
        setValue(value);
        break;
      case "multiselect":
        value = value.map((v) => v.id !== undefined ?  v.id : v.value);
        setValue(e.target.value);
        break;
      case "autocomplete":
        value = value ? value.id : null
        setValue(value);
        break;
      case "checkbox":
        value = value ? 1 : 0;
        setValue(value);
        break;
      case "editor":
        value = e.html
        setValue(value);
        break;
      case 'treeview':
        value = fvalue
        value[e.item.id] = !e.item.checked
        if (e.item.items) {
          e.item.items.forEach(child => {
            value[child.id] = !e.item.checked
          })
        }
        var parent = props.data.items.find(item => !item.items ? false : item.items.find(child => child.id == e.item.id))
        if (parent) {
          if (!e.item.checked) {
            value[parent.id] = true;
          }
          else if(!parent.route){
            
            value[parent.id] = !!parent.items.filter(i => i.id != e.item.id).find(i => i.checked)
          }
        }

        setValue({ ...value })
        break;
        
      default:
        setValue(value);
        break;
    }
    if (props.onChange && (props.data.type != 'date' || (!e.target.value || e.target.value.getFullYear() > 1000))) {
      props.onChange(props.data, value);
    }

    // }
  };

  
  const handleUploadInput = event => {
    hiddenFileInput.current.click();
  };
  const PickerChange = (e) => {
    setValue(e.value)
    props.onChange(props.data, e.value);
  };

  const AddToList = (e) => {
    
    console.log(e)
  }


  const AddFile = (e) => {
   
    props.onChange(props.data, e.target.files[0]);
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload =  () => {
        setValue(reader.result)
        
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
    };
   
   setFileName(e.target.files[0].name)

  };

  const RemoveFile = (event) => {
    let newFilePreviews = [...props.data.value];
    event.affectedFiles.forEach((file) => {
      let index = newFilePreviews.findIndex(
        (f) => (f.lastModified = file.getRawFile().lastModified)
      );

      if (index > -1) {
        newFilePreviews.splice(index, 1);
      }
    });
    props.onChange(props.data, newFilePreviews);
  };

  const changeAC = (e) => {
    setFilterValue(e.filter.value)
    setLoading(true);
    let andWhere = '';
    if (props.data.andWhere ) {
      andWhere = '&' + props.data.andWhere + (props.feildsData  && props.feildsData[props.data.andWhere] ? ( "=" + props.feildsData[props.data.andWhere]) : '')
    } 
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/suggestions/${props.data.searchType}/?q=${e.filter.value}${andWhere}`
      )
      .then(({ data }) => {
        setAcdata(data);
        setLoading(false);
      });
    // dispatch(autocomplate({model : props.data.searchType, term : e.filter.value}))
  };
  const errorMag = (field) => {
    return errorValidation ? errorValidation?.find(error => error.field == field)?.message : null;
  };

  
  const label = props.data.label ? (
    <>
    <Label editorId={props.data.name}>{props.data.label}&nbsp;  {props.data.required && <span className="required">*</span>}</Label> 
   
    </>
  ) : null;
  switch (props.data.type) {
    case "text":
    case "email":
    case "tel":
    case "number":
    case 'float':
      return (
        <div className="input_wrap">
          {label}
          {props.data.placeholder 
                ? <FloatingLabel
                    label={props.data.placeholder}
                    editorValue={props.data.value}
                    editorId={props.data.param}>
                      <Input
                className={`input_class input_with_float  ${errorMag(props.data.fieldName) ? 'errorInput' : ''}`}
                id={props.data.param}
                value={fvalue}
                readOnly={props.data.readOnly}
                onChange={Change}
                autoFocus={props.autoFocus || false}
                      /></FloatingLabel>
                : <Input type={ (props.data.type == 'number' || props.data.type == 'float') ? 'number' : 'text'}  className={`input_class  ${errorMag(props.data.fieldName)?'errorInput':''}`}
                id={props.data.param}
                value={fvalue}
              readOnly={props.data.readOnly}
              autoFocus={props.autoFocus || false}
              step={props.data.type === 'float' ? "0.1" : ''}
              
              onBlur={Blur}
              max={props.data.max || false}
              min={props.data.min || false}
              onChange={Change} />}               
          {errorMag(props.data.fieldName) && <Error>{errorMag(props.data.fieldName)}</Error>}
        </div>
      );
    case 'map':
      return (
        <div className="input_wrap">
          {label}
        
          <Autocomplete
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            defaultValue={fvalue}
           apiKey={'AIzaSyBqI0J-BzLVgttIZxRbFoUGzudClQEbgAU'}
           onPlaceSelected={ChangeMap}
          options={{
            language : 'iw',
            offset : 0,
            components : 'country:il', 
            types : ["street_address", 'route', 'locality'],
          }}
          language={'iw'}
        />
          {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "color":
      return (
        <div className="input_wrap">
          {label}
          <ColorPicker
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            value={fvalue}
            onChange={PickerChange}
            view="gradient"
            defaultValue={"green"}
          />
          {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "date":
      return (
        <div className="input_wrap">
          {label}
          <DatePicker
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            value={fvalue && typeof fvalue == 'string' ? new Date(fvalue) : (fvalue ? fvalue : null)}
            formatPlaceholder="formatPattern"
            label={props.data.placeholder}
            placeholder={false}
            format={props.data.format || "dd/MM/yyyy"}
            onChange={Change}
            autoFocus={props.autoFocus || false}
            calendar={props.data.format == 'MM/yyyy' ? MonthsCalendar : null}
            min={props.data.min ? new Date(props.data.min) : undefined}
            max={props.data.max ? new Date(props.data.max) : undefined}
            readonly={props.data.readOnly}
          />
         {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "time":
      const arr = []
      for (let i = 7; i < 23; i++) {
        for (let j=0; j < 4; j++) {
          arr.push({
            name: `${i}:${j === 0 ? `00` : 15 * j}`,
            value: `${i}:${j === 0 ? `00` : 15 * j}`
          });
        }
      }

       const timeValue = arr?.find(
        (o) => o.value == fvalue
      );
      
      return (
        <div className="input_wrap">
          {label}
          <DropDownList
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            style={{ width: "250px" }}
            data={[...[{ name: '-ללא בחירה-', value: null, id: null }], ...arr]}
            label={props.data.placeholder}
            textField="name"
            value={fvalue ? timeValue : ''}
            popupClass={'popup'}
            dataItemKey={props.data.options && props.data.options[0]?.id ? "id" : "value"}
            onChange={Change}
            readonly={props.data.readOnly}
          />
          {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "date-range":
      
      return (
        <div className="input_wrap">
          {label}
          <DateRangePicker
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            onChange={Change}
            readonly={props.data.readOnly}
            value={fvalue}
          />
          {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "select":
      const filterDdlChange = (event)  => {
        setDdlData(filterBy([...[{name: '-ללא בחירה-', value: null, id: null}], ...props.data.options], event.filter))
      }
      const value = ((fvalue === undefined || fvalue === null) ? null : props.data?.options?.find(
        (o) => o[props.data.options[0].id !== undefined ? "id" : "value"].toString() === fvalue.toString()
      ));

      const copyToClipboard = () => {
        let key = props.data.options && props.data.options[0]?.id ? "id" : "value";

        let val = props.data.options.find(o => o[key] == fvalue)?.name;
        navigator.clipboard.writeText(val);
        setDdlCopyTitle('הנתונים הועתקו');

        setTimeout(() => {
        setDdlCopyTitle('העתק נתונים');
        
        }, 2000);
      }
      return (
        <div className="input_wrap">
          {label}
          <div style={{ display: 'flex', flexDirection: 'row'}} className="ddl_class">
          <DropDownList
            className={`input_class ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            style={{ width: props.data.isView ? '100%': "250px" }}
            data={ddlData}
            value={fvalue !== undefined ? value : ''}
            label={props.data.placeholder}
            textField="name"
            popupClass={'popup'}
            dataItemKey={props.data.options && props.data.options[0]?.id ? "id" : "value"}
            onChange={Change}
            readonly={props.data.readOnly}
            filterable={true}
            onFilterChange={filterDdlChange}
            autoFocus={props.autoFocus || false}
            />
             
            {fvalue && props.data.isView && <Button icon="copy" className="tooltip" onClick={copyToClipboard}>
              <span class="tooltiptext" id="myTooltip">{ddlCopyTitle}</span>
            </Button>}
             
            </div>
  {errorMag(props.data.fieldName) && !value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "multiselect":

    const tagRender = (tagData, li) => {
    return (
      <span className="overme" title={tagData.text}>
        {tagData.text}
      </span>
      );
     
      }
      

      const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span className={"k-list-optionlabel multi-item " + (itemProps.focused ? 'focused' : '')}>
        {li.props.children}
        {itemProps.selected && <span style={{ position: 'absolute', left: '20px'}} className="k-icon k-i-check"></span>}

      </span>
        );
      
        return React.cloneElement(li, { ...li.props, className: 'k-list-item ' }, itemChildren);
  };
      const filterMultiChange = (event) => {
        setMultiData(filterBy([...props.data.options], event.filter))
      }
    
      return (
        <div className="input_wrap" 
            autoFocus={props.autoFocus || false}
>
          {label}
          <MultiSelect
            tagRender={tagRender}
            itemRender={itemRender}
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            data={multiData}
            label={props.data.placeholder}
            autoClose={false}
            filterable={true}
            onFilterChange={filterMultiChange}
            textField="name"
            tags={fvalue && typeof fvalue == 'object' && fvalue.length > 0 ? [{
              text: fvalue.map(f => {
                if (typeof f == 'object') {
                  return f.name
                }
           
                return props.data.options.find(
                  (o) => o[props.data.options[0].id ? "id" : "value"] == f
                )?.name;
                
              }).join(', '),
              data: [...fvalue]
            }] : (fvalue && typeof fvalue == 'string' ? [{
              text: fvalue.split(',').map(f => {
                if (typeof f == 'object') {
                  return f.name
                }
           
                return props.data.options.find(
                  (o) => o[props.data.options[0].id ? "id" : "value"] == f
                )?.name;
                
              }).join(', '),
              data: [...fvalue.split(',')]
            }]
              : [])}
            listNoDataRender={listNoDataRender}
            style={{maxHeight: '30px', width: '250px'}}
            dataItemKey={props.data.options && props.data.options[0]?.id !== undefined ? 'id' : 'value'}
            onChange={Change}
            readonly={props.data.readOnly}
            value={(fvalue == '' || !fvalue) ? [] : (typeof fvalue == 'string' ?  fvalue.split(',').map(f => {
                if (typeof f == 'object') {
                  return f
                }
           
                return props.data.options.find(
                  (o) => o[props.data.options[0].id ? "id" : "value"] == f
                );
                
              }) : fvalue.map(f => {
                if (typeof f == 'object') {
                  return f
                }
           
                return props.data.options.find(
                  (o) => o[props.data.options[0].id ? "id" : "value"] == f
                );
                
              }))}
            
          />
        {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "autocomplete":
      const acvalue = acdata ? acdata.find(
        (o) => o.id == fvalue
      ) : '';
      return (
        <div className="input_wrap">
          {label}
          {props.data.form && <div className={"combo-header"} onClick={() => setOpenPopup(true)} title={'צור חדש'}> + </div> }
          {openPopup && <ComboPopup form={props.data.form} addToParent={AddToParent} close={() => setOpenPopup(false)}></ComboPopup> }
          <ComboBox
            className={`input_class  ${(errorMag(props.data.fieldName) && !fvalue)?'errorInput':''}`}
            data={acdata}
            loading={loading}
            value={fvalue && acdata?.find(
              (o) => o.id == fvalue
            ) ? acdata?.find(
              (o) => o.id == fvalue
            ): fvalue}
            label={props.data.placeholder}
            listNoDataRender={listNoDataRender}
            textField="label"
            onFilterChange={changeAC}
            filterable={true}
            dataItemKey="id"
            onChange={Change}
            readonly={props.data.readOnly}
          />
         {errorMag(props.data.fieldName) && !fvalue?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
        //<ComboBox  filterable={true}  data={currentSuggestion} value={fvalue}  onChange={Change}
        //textField="label"  dataItemKey="id" loading={loading}
        ///>
      );
      case "editor":
        return(
          <div className="input_wrap">
          {label}
          <Editor
          tools={[
            [Bold, Italic, Underline],
            [Undo, Redo],
            [Link, Unlink],
            [AlignLeft, AlignCenter, AlignRight],
            [OrderedList, UnorderedList, Indent, Outdent],
          ]}
      
          defaultEditMode='div'
          contentStyle={{
            height: fvalue && fvalue.split(' ').length > 500 ? fvalue.split(' ').length / 5:  120,
          }}
          defaultContent={fvalue || ''}
              onChange={Change}
              onBlur={Blur}
        />
        </div>
        
        );

    case "checkbox":
      return (
        <div className="input_wrap">
          <Checkbox
            className={`input_class check ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            onChange={Change}
            value={!!fvalue}
            label={props.data.label}
            readonly={props.data.readOnly}
          />
        {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "textarea":
      return (
        <div className="input_wrap">
          {label}
          <textarea
            className={`input_class  ${(errorMag(props.data.fieldName) && !fvalue)?'errorInput':''}`}
            onChange={Change}
            onBlur={Blur}
            value={fvalue}
            style={{
              minHeight: (fvalue ? ((fvalue.length / 2) + (fvalue.split('\n').length * 10)) : 60) + 'px'
            }}
            readonly={props.data.readOnly}
          ></textarea>
         {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );
    case "file":
        return (
          <div className="input_wrap">
            {label}
            <input
                type='file'
                className='upload up'
                id='up'
                onChange={AddFile}
                ref={hiddenFileInput}
            />
            {errorMag(props.data.fieldName) && !props.data.value ? <Error>{errorMag(props.data.fieldName)}</Error> : ''}
         
            {fvalue ?
            (  typeof fvalue === 'string' && (fvalue.includes('png') || fvalue.includes('jpg') || fvalue.includes('jpeg'))
              ? <a href={(typeof fvalue === 'string' && !fvalue.includes('base64') ? (process.env.REACT_APP_API + '/') : '' ) +  fvalue} target="_blank" >
                <img style={{maxWidth:'100%',maxHeight: '70px', float: 'left'}} src={ (typeof fvalue === 'string' && !fvalue.includes('base64') ? (process.env.REACT_APP_API + '/') : '' ) +  fvalue}></img>
                </a> 
              : <a style={{float: 'left'}} download href={ ( typeof fvalue === 'string' && !fvalue.includes('base64') ? (process.env.REACT_APP_API + '/') : '' ) +  fvalue} >הורדת הקובץ </a>) : ''}

            </div>
        )
      return (
        <div className="input_wrap">
          {label}{" "}
          <Upload
            className={`input_class  ${(errorMag(props.data.fieldName) && !props.data.value)?'errorInput':''}`}
            multiple={true}
            files={props.data.value}
            onAdd={AddFile}
            saveUrl={`${process.env.REACT_APP_API_URL}/${props.data.model}`}
            saveHeaders={{'Authorization': `Bearer ${AuthToken}`}}
           // autoUpload={false}
           // showActionButtons={false}
            saveMethod={'PUT'}
            saveField={props.data.fieldName}
            onRemove={RemoveFile}
          />
         {errorMag(props.data.fieldName) && !props.data.value?<Error>{errorMag(props.data.fieldName)}</Error>:''}
        </div>
      );

    case 'list':
      const CustomItem = (props) => {
        return (
          <div style={{ padding: '5px', display: 'flex', flexDirection: 'row', width: '90%' }}>
            <div style={{width: '45%'}}> 
              שם קובץ
              <Input type={'text'} value={props.dataItem.file_name}
                onChange={(e) => {
                  setValue(fvalue.map((v, i) => {
                    if (props.index != i) {
                      return v;
                    }
                    return {
                      ...v,
                      file_name: e.target.value
                    }
                  }))
                }} />
            </div>
            <div style={{width: '45%', paddingRight: '5px'}}>             
                 לינק קובץ
              <Input type={'text'} value={props.dataItem.file_link}
                onChange={(e) => {
                  setValue(fvalue.map((v, i) => {
                    if (props.index != i) {
                      return v;
                    }
                    return {
                      ...v,
                      file_link: e.target.value
                    }
                  }))
                }} />
            </div>
            <div>
              <Button style={{ marginLeft: '10px', marginTop: '20px' }}
                onClick={(e) => setValue(fvalue.filter((v, i) => props.index !== i))}>-</Button>
              </div>
          </div>

        )
      }

      return (
        <div className="input_wrap check ">
          <Button style={{ float: 'left' }} onClick={(e) =>
            
            setValue([...fvalue, {file_link : '', file_name: ''}])
          }>+ </Button>
          {label}
         
         <ListView
        data={fvalue}
        item={CustomItem}
        style={{
          width: "100%",
        }}
      />
          </div>
      )

    case 'treeview':
      return (
        <div className="input_wrap check ">
          {label}
          <TreeView
              data={treeItems}
              checkboxes={props.data.isEdit === undefined ?  true : props.data.isEdit} onCheckChange={Change}
          />
        </div>)
    default:
      return <div> {props.data.type} not found</div>;
  }
};

const BaseInput = (props) => {

  switch (props.data.type) {
        case 'list':
            return <List data={props.data} onChange={props.onChange} onBlur={props.onBlur} autoFocus={props.autoFocus}/>;
        case 'select':
        case 'multiselect':   
        case 'date':
        case 'autocomplete':         
        return (<GenericInput data={props.data} feildsData={props.feildsData} onChange={props.onChange} autoFocus={props.autoFocus} />)
        default:
            if(props.data.placeholder) {
                return (                
                // <FloatingLabel
                //     label={props.data.placeholder}
                //     editorValue={props.data.value}
                //     editorId={props.data.param}>  </FloatingLabel>
                    <GenericInput data={props.data} onChange={props.onChange}/>
              
                )
            }
        return (<GenericInput data={props.data} onChange={props.onChange} onBlur={props.onBlur} autoFocus={props.autoFocus}/>)
           
            
    }
}

export default BaseInput
