import { Button } from '@progress/kendo-react-buttons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getIndex } from '../../../redux/actions/baseDataActions'
import BaseInput from '../baseInput/BaseInput'
import './Filters.css'

const Filters = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [params, setParams] = React.useState({... props.defParams || {}, name: props.model});
  const [clear, setClear] = React.useState(false);
  const [flag, setFlag] = React.useState('');
    
  
  useEffect(() => {
      setParams({ ...Object.fromEntries(new URLSearchParams(location.hash.replace('#', ''))), name: props.model })
  }, [location.pathname])

  const filterByParam = (filter, value, sendToServer = true) => {
    if (!sendToServer) {
        return
      }
      setClear(false)
        let updatedValue = {
          name: props.model
        }
        updatedValue[filter.param] = value

    
       
        setParams(params => ({
            ...params,
            ...updatedValue
          }));
      
      if(props.FilterChanged) {
          props.FilterChanged({
            ...params,
            ...updatedValue
          })
      } else {
        
        dispatch(getIndex({
            ...params,
            ...updatedValue
        }))
      }
      
    }
    const resetFilters = () => {
     setClear(true);
      setParams({
        name: props.model
      });

    
      
      if(props.FilterChanged) {
          props.FilterChanged({})
      } else {
        dispatch(getIndex({
          name: props.model
        }))

      }
      
  }
  
 return (
    <div className='filters'>        
        {props.settings?.filters?.filter(filter => filter.show === undefined || filter.show).map(filter => {
            if(filter.list) {
               filter.items = props.settings[filter.list]
            }
            filter.placeholder = props.settings.labels && props.settings.labels[filter.placeholder] ? props.settings.labels[filter.placeholder] : filter.placeholder
            return filter
          }).map((filter, i) => (            
            <BaseInput  key={props.model + i}  data={{...filter, clear: clear, value: params[filter.param] || null }} onChange={filterByParam} />
        ))}
        {props.settings?.filters && <div className='input_wrap clear-btn'>
        <Button fillMode="flat"  onClick={resetFilters}>ניקוי שדות</Button></div> }
      
    </div>
  )
}

export default Filters
