
import {
  Input,
} from "@progress/kendo-react-inputs";
import {
  ListView,
} from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";

import {
  Label,
} from "@progress/kendo-react-labels";

const List = (props) => {
    const [fvalue, setValue] = useState(props.data.value);
    
    console.log('here')      
  const label = props.data.label ? (
    <>
    <Label editorId={props.data.name}>{props.data.label}&nbsp;  {props.data.required && <span className="required">*</span>}</Label> 
   
    </>
    ) : null;
    
    const ChangeParent = (index, name, value) => {
        fvalue[index][name] = value;
        setValue(fvalue)
          props.onChange(props.data, fvalue, false);
    }

    const RemoveParent = (index) => {
        let nFvalue = fvalue.filter((v, i) => index !== i)
        setValue(nFvalue)
        props.onChange(props.data, nFvalue, false);
    }

    const CustomItem = (props) => {
        const [fileName, setFileName] = useState(props.dataItem.file_name)     
        const [link, setLink] = useState(props.dataItem.file_link)
        
        const Change = (e) => {
            setFileName(e.target.value);
            ChangeParent(props.index, 'file_name', e.target.value)
          
        
        }

     

        const ChangeLink = (e) => {
            setLink(e.target.value);
            ChangeParent(props.index, 'file_link', e.target.value)
        }

        const Remove = (e) => {
            RemoveParent(props.index)
        }

        return (
          <div style={{ padding: '5px', display: 'flex', flexDirection: 'row', width: '97%' }}>
            <div style={{width: '50%'}}> 
              שם קובץ
              <Input type={'text'} value={fileName}
                onChange={Change} />
            </div>
            <div style={{width: '50%', paddingRight: '5px'}}>             
                 לינק קובץ
              <Input type={'text'} value={link}
                onChange={ChangeLink} />
            </div>
            <div>
              <Button style={{ marginRight: '5px', marginTop: '20px' }}
                onClick={Remove}>-</Button>
              </div>
          </div>

        )
      }

      return (
        <div className="input_wrap check ">
          <Button style={{ float: 'left' }} onClick={(e) =>
            
            setValue([...fvalue, {file_link : '', file_name: ''}])
          }>+ </Button>
          {label}
         
         <ListView
        data={fvalue}
        item={CustomItem}
        style={{
          width: "100%",
        }}
      />
          </div>
      )

}

export default List
