import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useEf } from "react-redux";
import { Link ,useLocation} from "react-router-dom";
import "./Header.css";
import logo from "../../../assets/images/logo.png";
import { Avatar } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { logout } from "../../../redux/actions/authActions";
import { setExppandedNav } from "../../../redux/actions/navbarActions";

import { useNavigate } from 'react-router-dom'
import { Button } from "@progress/kendo-react-buttons";
import Title from "../title/Title";
import { getIndex } from "../../../redux/actions/baseDataActions";
import Notifications from "./Notifications";
const maxMobileWidth = 767;
const links = [
  {
    path: "/",
    title: "מקבלי שירות",
  },
  {
    path: "/concats",
    title: "אנשי קשר",
  },
];

const Header = (props) => {
  const base  = useSelector(state => state.base)
  const location = useLocation();
  const currentModel = props.name || location.pathname.substring(1);
  const negative = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, FirstName, LastName, user } = useSelector(
    (state) => state.auth
  );
  const { expanded, items } = useSelector((state) => state.navbar);

  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const onClick = () => {
    setShow(!show);
  };

  const [history, setHistory] = useState(sessionStorage.getItem('history') ? JSON.parse(sessionStorage.getItem('history')) : [])

  useEffect(() => {
    const newHistory = history || []
    if (location.pathname != '/' && location.pathname != history[history.length -1]) {
      newHistory.push(location.pathname + (location.hash || ''))
      setHistory(newHistory)
      sessionStorage.setItem('history',JSON.stringify(newHistory))
    }
    
  },[location.pathname])
 
  const logoutFunc = () => {
    dispatch(logout());
    setShow(!show);
    window.location.replace("/");
  };

  const modelName =  props.name//props.subName  ? (currentModel + '/' + props.subName) : currentModel
  const title = modelName ? items.find(i => i.Url?.split('#')[0] == modelName)?.Name : 'דאשבורד'; 
  
  const returnTo = base?.currentItem?.returnTo || currentModel 

  const negativeTo = () => {
    if (returnTo) {
      const modelIndex = base[returnTo]?.LOOKUP?.gridSettings[returnTo];
      if (modelIndex)    
        dispatch(getIndex({
          ...modelIndex.filters || {},
          name: returnTo,
          page: modelIndex.page || 1,
          "per-page": modelIndex.take || 100,
          sort:  (modelIndex ? modelIndex.sort : '-Id')
        }))
    }
    history.pop()
    const pre = history.pop()
    console.log(pre)
    sessionStorage.setItem('history', JSON.stringify(history))
    setHistory(history)

    negative(pre || -1
      //location.hash && !location.pathname.includes('report') ? returnTo :
      )//'/' + returnTo)
  }


  return (
    <section className="header is-sticky">
      {isAuthenticated ? (
        <>
      <div className="page_title">
        {!!props.subName 
          && <Button fillMode="flat" style={{ marginLeft: '5px' }}
            onClick={negativeTo}
            themeColor={'auto'} size={'large'} icon={'arrow-right'} title="חזור" />}
      {title}</div>
      

          <div className="left-header">
     
        <a onClick={onClick} ref={anchor} className="avatar">
          <Avatar type={"text"} size="small" style={{backgroundColor : user?.Color}}>
            <span>
              {" "}
              {FirstName ?  FirstName.substr(0, 1) : ""}
              {LastName ?  LastName.substr(0, 1) : ""}

              {" "}
            </span>
          </Avatar>
            </a>
            </div>

          </>
      ) : null}

      <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
        {FirstName + ' ' + LastName}
        <br />
        <br />
        <span className="role">{user?.RoleName}</span>
        <br />
        <a className="logout" onClick={logoutFunc}>
          התנתקות
        </a>
      </Popup>
    </section>
  );
};

export default Header;
