
import {
    convert,
  } from "../../../redux/actions/baseDataActions"
  import { Button } from "@progress/kendo-react-buttons";
  import { useDispatch, useSelector } from "react-redux";
import { asyncActionStart, asyncActionFinish, asyncActionError } from "../../../redux/actions/asyncActions";
import axios from 'axios'
import { useNavigate } from "react-router-dom";


  const ExtraAction = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const navigateTo = (url) => {
        window.location = url 
               
    }
    const enter = async (action) => {
      switch (action) {
        case "delete":
        //  dispatch(open("delete_" + modelName + "_" + props.dataItem.id));
          break;
        case "convert":
          try {  
          dispatch((asyncActionStart()))
            const res = await axios.post(process.env.REACT_APP_API + "/v1/leads/"  + props.id + '/convert')
            window.location = "/clients/" + res.data?.data.id 
                 
            }
            catch(err) {
                
              console.log(err)
                let msg = err.response?.data?.message || 'חלה שגיאה כללית'
                dispatch(asyncActionError(msg))
              }
          
          //dispatch(convert({ model: props.model, id: props.id, data: props.data, isParent : props.isParent }));
          break;
      }
    };


    const getIcon = (action) => {
      const map = {
        delete: "delete",
        view: "eye",
        update: "edit",
        "inline-update": "edit",
        convert: "user",
      };

      if (map[action]) {
        return map[action];
      }
      return action;
    };
    const getActionTitle = (action) => {
      const map = {
        delete: "מחיקה",
        convert: "העברה למקבלי שירות",
      };

      if (map[action]) {
        return map[action];
      }
      return action;
    };
    const getColor = (action) => {
      const map = {
        delete: "light",
        convert: "danger",
      };

      if (map[action]) {
        // return map[action];
        return "auto";
      }
      return action;
    };

    return (
            <Button
              key={props.action}
              onClick={() => enter(props.action)}
              themeColor={getColor(props.action)}
              //icon={getIcon(action)}
              title={getActionTitle(props.action)}
            >{getActionTitle(props.action)}</Button>
          )
  };

  export default ExtraAction