import {useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Route, HashRouter,  useLocation, useParams, useNavigate} from 'react-router-dom';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

import Title from '../layout/title/Title';
import {  getView, getIndex, getLog } from '../../redux/actions/baseDataActions'
import './BaseView.css'
import Grid from '../layout/grid/Grid';
import Sections from '../layout/sections/Sections'
import Filters from '../layout/filters/Filters';
import Base from '../base/Base';
const BaseView = (props) => {
 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [visible, setVisible] = useState(null);
  const currentModel = props.model
  const { liveActions }  = useSelector(state => state.async)

  
  useEffect(() => {
    if(currentModel) {        
        dispatch(getView({model: currentModel, isParent : true}))
    }
    setSelected(location.hash ? Number(location.hash.replace('#', 0)) : 0);

  }, [currentModel])
  
  const [selected, setSelected] = useState(Number(location.hash.replace('#', 0) || 0));
  const { currentItem, currentLog }  = useSelector(state => state.base)

  const [params, setParams] = useState(null);

  

  const selectTab = (e)  =>{
  
    setSelected(e.selected)
    
   // dispatch(getIndex({name: currentItem?.tabs[e.selected]?.url}))

    navigate(location.pathname + '#' + e.selected, { replace : false})

  }


  if(!currentItem) {
    return (
      <div className='loader' >
          <Loader themeColor="dark" type="converging-spinner" size='large'/>
      </div>
    )
  }
      
    return (
    <section className='content-page-base-view '>           
                <TabStrip  selected={selected} onSelect={selectTab} >
                    {currentItem?.tabs ? currentItem?.tabs.map((tab, i) => (
                        <TabStripTab title={tab.title} key={i} >
                              <div className='base-view-content'>
                                  <Base mame={tab.url} lookup={tab.lookup} isParent={false}/>
                                </div>
                            </TabStripTab>
                    )) : null}
                </TabStrip>
     </section>
    )

}

export default BaseView
