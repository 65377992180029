import { Card, CardTitle, CardSubtitle } from '@progress/kendo-react-layout';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import './TotalCount.css'

const TotalCount = (props) => {
  const model = useSelector((state) => state.base[props.model]);

  
  const getTitle = (key) => {
    if (key.title) {
      return key.title;
    }

    return model?.LOOKUP?.labels && model.LOOKUP.labels[key] ? model.LOOKUP.labels[key] : key;
  };

 return (
    <div className='total'>        
         {Object.keys(model?.data?.total_count).map(key => {
           return (<Card className="total-card">
                  <CardTitle className='card-title'>
                    {getTitle(key)}
                  </CardTitle>
                  <CardSubtitle>
                    <h2> {model?.data?.total_count[key]}</h2>
                  </CardSubtitle>
                
             </Card>)
         })}
    </div>
  )
}

export default TotalCount
