import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import Grid from "../../layout/grid/Grid";
import { useDispatch, useSelector } from "react-redux";
import { close, open } from "../../../redux/actions/modalActions";
import DeleteModal from "../../layout/deleteModal/DeleteModal";
import { updateItem, getIndex, getView } from "../../../redux/actions/baseDataActions";
import EditForm from "../../editForm/EditForm";


const Relation = (props) => {

    const dispatch = useDispatch()
    const { modal_name } = useSelector((state) => state.modal);
    
    const model = "clients/{id}/relation-events".replace('{id}', props.data.Id)
    const Open = () => {
        dispatch(getView({
                model: model,
                id: 0,
                isParent : false}))
        
        dispatch(open('relation-events_' + props.data.Id))
    }
    
    const Close = () => {
      
        dispatch(close());
    }

    
    return (
        <>
            <Button fillMode={'flat'}  title={"הוספת מגע"}  onClick={Open} icon=" fa fa-handshake">
              
            </Button>
            {(modal_name == 'relation-events_' + props.data.Id) &&
                <Dialog title={"הוספת מגע - " + props?.data?.ClientName} onClose={Close} dir="rtl"   style={{ zIndex: 100 }}>                
                    <EditForm
                            model={model}
                            mode={'create'}
                            close={Close}
                            isParent={false}
                            id={null}
                            section={0}
                            />
                
            </Dialog>
            }
    </>
  );
};

export default Relation;
