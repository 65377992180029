import {useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Route, HashRouter,  useLocation, useParams, useNavigate} from 'react-router-dom';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { toast } from 'react-toastify';

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import  Notes  from '../notes/Notes'
import EditForm from '../editForm/EditForm'
import { Loader } from "@progress/kendo-react-indicators";

import Title from '../layout/title/Title';
import { lookup, getView, getIndex, getLog, updateFields } from '../../redux/actions/baseDataActions'
import './ViewForm.css'
import Grid from '../layout/grid/Grid';
import Sections from '../layout/sections/Sections'
import Filters from '../layout/filters/Filters';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios'
import { asyncActionError, asyncActionStart } from '../../redux/actions/asyncActions';
import { close, open } from '../../redux/actions/modalActions';
import DeleteModal from '../layout/deleteModal/DeleteModal';

const ViewForm = (props) => {
 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {id, parentId } = useParams()
  const currentModel = props.model.replace('{parentId}', parentId || '')
  const [visible, setVisible] = useState(null);
  const { liveActions }  = useSelector(state => state.async)
  const { currentItem, currentLog } = useSelector(state => state.base)
  const base = useSelector(state => state.base)
   const { expanded } = useSelector(
    (state) => state.navbar
  );

    const { modal_name } = useSelector((state) => state.modal);
    const [openNote, setOpenNotes] = useState(true);

  
  useEffect(() => {
    //if (currentModel && !liveActions.includes(currentModel)) {
      
      
        dispatch(getView({model: currentModel, id: id, isParent : props.isParent}))
        dispatch(getLog({model: currentModel, id: id}))
    //}
  }, [location.pathname])

  useEffect(() => {
        setSelected(Number(location.hash.replace('#', '') || 0))
  }, [location.hash])
  
  if (currentItem && currentItem.tabs === undefined) {
    navigate('/' + currentModel)
  }
  const [selected, setSelected] = useState(Number(location.hash.replace('#', '') || 0));

  const [params, setParams] = useState(null);

  const openEditDialog = (sectionId) => {   
    var feilds = currentItem.feildsData
    feilds['saved_on_server'] = false
    dispatch(updateFields(feilds))
    setVisible(sectionId)
   
}


const closeDialog = () => {
    setVisible(null)
    
  dispatch(updateFields({ data: { ...currentItem.feildsData }, isParent:  props.isParent, saved_on_server : false}))
  }


  const selectByKey = (key) => {
    let index = currentItem?.tabs?.findIndex(t => t.key == key);
    if (!index) {
      return;
    }
  setSelected(index)
   // setSelected({selected : index.toString()})
  }

  

  const selectTab = (e)  =>{
  
    setSelected(e.selected)
    navigate(location.pathname + '#' + e.selected, { replace : false})
    if (currentItem?.tabs[e.selected || 0].url) {
      const selectModelName = currentItem?.tabs[e.selected].url.replace('{id}', id)
    const selectModelSettings = base[selectModelName]?.LOOKUP?.gridSettings[selectModelName]
    dispatch(getIndex({
      name: currentItem?.tabs[e.selected].url.replace('{id}', id),
      page: selectModelSettings ? selectModelSettings.page : 1,
      "per-page": selectModelSettings ? selectModelSettings.take : 100,
      sort: selectModelSettings? selectModelSettings.sort : '-Id'
    }))

     /* dispatch(getIndex({
        name: currentItem?.tabs[e.selected || 0].url.replace('{id}', id),
          page: 1,
          "per-page": 5
      }))*/
    }
    else {
      dispatch(getView({ model: currentModel, id: id, isParent: props.isParent, notClear: true }))
      currentItem?.tabs[e.selected || 0]?.sections.forEach((section) => {
        if (section.url) {
          dispatch(getIndex({
            name: section.url.replace('{id}', id),
            page: 1,
            "per-page": 100
          }))   
        }
      })
    }

  }

  const selectedAndFresh = (tab) => {
    if(!tab) {
      return;
    }
  
    selectTab({selected :tab})
    dispatch(getIndex({
      name: currentItem?.tabs[tab].url.replace('{id}', id),
      page: 1,
      "per-page": 100,
    }))

  }

  const GenericComponent = ({component}) => {
    const GetAffiliateWeb = async () => {
      let affiliate_url = currentItem?.feildsData.affiliate_url
    
      if(!affiliate_url) {
        let {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${currentModel}/${currentItem?.feildsData.id}/affiliate`)
        affiliate_url = data?.data?.affiliate_url;
      }

      const url = 'https://aff.doctorita.co.il/home/';
      navigator.clipboard.writeText('הי קישור למערכת ניהול ההרשמות שלכם: \n   ' +  url);

      toast.success(`הלינק הועתק`, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        });

    }
    const GetAffiliateUrl = async () => {
      let affiliate_url = currentItem?.feildsData.short_aff_link 
        ? currentItem?.feildsData.short_aff_link 
        : currentItem?.feildsData.affiliate_url
    
      if(!affiliate_url) {
        let {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${currentModel}/${currentItem?.feildsData.id}/affiliate`)
        affiliate_url = data?.data?.short_aff_link;
      }

      const url = affiliate_url?.includes('https') ? affiliate_url :  'https://ob.doctorita.co.il/home/?source=' + affiliate_url;
      navigator.clipboard.writeText(url);

      toast.success(`הלינק הועתק`, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        });
    }

    switch(component) {
        case 'affiliate_url':
          return (<>
              <Button themeColor="secondary" onClick={GetAffiliateUrl} icon={'group'}>לינק שותף לאתר</Button>
          </>)
        case 'affiliate_web':
            return (<>
                <Button themeColor="secondary" onClick={GetAffiliateWeb} icon={'group'}>לינק לאיזור אישי שותפים</Button>
            </>)
        
        default: 
        return (<></>)
    }
  }


  const gerericBtn = (action) => {
    switch(action.type) {
        case 'create':
          break;
      case 'request_confirm':
        dispatch(open(action.link))
          break;
        case 'request':
          dispatch(asyncActionStart())
            axios[action.method](process.env.REACT_APP_API  + action.link, action.params)
              .then((res) => {
                dispatch(getView({ model: currentModel, id: id, isParent: props.isParent, notClear: true }))
                
              const freshNotes = res.headers['x-reload-notes']

          if(freshNotes) {
            
            const fresh = JSON.parse(freshNotes)
          
            if (fresh.model == currentModel) {
              dispatch(getLog(fresh))
            }
          }

            const freshIndex = res.headers['x-reload-index']

          if(freshIndex) {          
            console.log(JSON.parse(freshIndex))
            dispatch(getIndex({
                name: JSON.parse(freshIndex).name,
                sort:  JSON.parse(freshIndex).sortBy || "-Id",
                page: 1,
                "per-page": JSON.parse(freshIndex).isParent ? 100 : 100,
            }))
            
          }




         const message = res.headers['x-message']
         if(message) {
          JSON.parse(message).forEach(m => {
            toast(m, {
              type: 'warning',
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              });
            })

         }
                }
              ).catch((err) => {
         console.log(err)
                let msg = err.response?.data?.message || 'חלה שגיאה כללית'
                dispatch(asyncActionError(msg))
              })
          break;
        case 'request_navigate':
           dispatch(asyncActionStart())
            axios[action.method](process.env.REACT_APP_API + "/v1/" + action.link, action.params)
              .then(({ data }) => {
            
                 window.location =  "/" + action.navigateTo + data?.data.Id 
                 
                }
              ).catch((err) => {
         
                let msg = err.response?.data?.message || 'חלה שגיאה כללית'
                dispatch(asyncActionError(msg))
              })
          break;
          default:
            break;

      }
  }

  if(!currentItem) {
    return (
      <div className='loader' >
          <Loader themeColor="dark" type="converging-spinner" size='large'/>
      </div>
    )
  }
    return (
      <section className='content'>
        
        {currentItem && <Title subTitle={currentItem.title_1 && currentItem.title_1+' • '+currentItem.title_2} title={currentItem.title}/>}
            {
          <div className='action-view' style={{ height: '50px', width: window && window.innerWidth > 600 
          ? ('calc(100vw - 20vw - ' + (expanded ? '315' : '130') + 'px')
          : '80vw' }}>
            {currentItem?.actions 
            //?.filter(act => !act.if || act.if.split('=').length < 2 || currentItem?.feildsData[act.if.split('=')[0]] == act.if.split('=')[1])
            ?.map(act => act.component ? <GenericComponent component={act.component}/> : (<>
              <Button themeColor={act.color} onClick={() => gerericBtn(act)} icon={act.icon || false}>{act.text}</Button>
              {act.confirm && modal_name && modal_name == act.link && <DeleteModal
                title={act.confirm.title}
                text={act.confirm.text}
                close={() => { dispatch(close()) }}
                delete={() => { dispatch(close()); gerericBtn({ ...act, type: 'request' }) }}
                delText={act.delText}
            />}
        
              </>
             ))}</div> 
            }
             
             {visible !== null && <Dialog dir="rtl"  style={{
              zIndex: 100
             }} onClose={closeDialog} title={currentItem.title +' '+currentItem.Id+ ' / ' + (currentItem?.tabs && currentItem?.tabs[selected]?.sections[visible]?.title?.length < 20 ? currentItem?.tabs[selected]?.sections[visible]?.title : '')}>
                        <EditForm model={props.model} id={id} mode={'edit'} close={closeDialog} tab={selected} section={visible} isParent={props.isParent}/>
                    </Dialog>}
          <div className='view-form-wrap'>
          <div className={'tabs-warpper'} style={{width:  window && window.innerWidth > 600 
            ? ('calc(100vw - 20vw - ' + ((expanded ? 315 : 130) + (openNote ? 0 : -300)) + 'px)')
            : '80vw'}} >          
            <TabStrip  selected={selected} onSelect={selectTab}>
              {currentItem?.tabs ? currentItem?.tabs.map((tab, i) => {
                switch (tab.type) {
                  case 'sections':
                    return (
                      <TabStripTab title={tab.title} key={i}>
                       
                        <Sections data={tab} Id={id} setTabSelected={selectByKey}
                          model={currentModel} openEditDialog={openEditDialog} />
                        </TabStripTab>)
                  case 'table':
                    
                    return (
                      <TabStripTab title={tab.title} key={i}>
                        <Filters settings={tab.LOOKUP} model={tab.url.replace('{id}', id)}/>

                        <Grid setTab={() => selectedAndFresh(tab.fresh_tab)}
                          setTabSelected={selectByKey}
                          title={tab.title}
                          section={tab.data} model={tab.url.replace('{id}', id)}
                          moreActions={tab?.moreActions}
                          sortBy={tab?.sort}
                          pageable={tab?.pageable === undefined ? true : !!tab.pageable}
                          actions={tab.actions} columns={tab.columns} isParent={false} />
                      </TabStripTab>
                    )
                  default:
                    return ''
                }
              }) :   
      <div className='loader' >
          <Loader themeColor="dark" type="converging-spinner" size='large'/>
      </div>
    
  
              }
                   
                

            </TabStrip>
            </div>

            { openNote ? <div className="side">
              <div className="closePopup" onClick={() => setOpenNotes(!openNote)}>
                <span className={"k-icon k-i-arrow-left"}></span>
              </div>
                <Notes model={currentModel} sendWhatsapp={currentItem.sendWhatsapp} />
            </div> :  <div className="openPopup" onClick={() => setOpenNotes(!openNote)}>
                <span className="k-icon k-i-arrow-right"></span>
              </div>
              }
        </div>
        </section>
    )

}

export default ViewForm
