import { useEffect, useState,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { useNavigate } from "react-router-dom";

import Title from "../layout/title/Title";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
  } from "@progress/kendo-react-charts";
import "./Home.css";
import { getIndex } from "../../redux/actions/baseDataActions";
import BaseInput from "../layout/baseInput/BaseInput";

const Home = (props) => {
  const dispatch = useDispatch();
  const negative = useNavigate();

  
  const { home } = useSelector(state => state.base)
  const { user } = useSelector((state) => state.auth);
   const [params, setParams] = useState({}); 
  const { liveActions }  = useSelector(state => state.async)

  const getMonthBefore=()=>{
    let date=new Date()
    let day=date.getDate()
    let month=(date.getMonth())
    let year=date.getFullYear()
    if(month >1){
      return year+'-'+(month) + '-'+  day
    }
    else{
      return (year-1)+'-'+12 + '-'+day
    }
  }
  useEffect(() => {
    
    if((!home || !home.data) && !liveActions.includes('home') ) {
      dispatch(getIndex({name: 'home'}))
    }
    getMonthBefore()
  }, [])
  

  const getTitle = () => {
    const data = home?.data?.dashbaord_data;
    if (!data) {
      return '';
    }
    return ''
  }

  



  if (!home) {
    return (
      <div className='loader' >
          <Loader themeColor="dark" type="converging-spinner" size='large'/>
      </div>
    )
  }
 
  var list = [
        
    {
      type: "box",
      title: "נתונים כללים",
      style: {
        minWidth: "30%"
      },
      data: [
        {
          key: "affiliates",
          title: 'שותפים',
          link: '/affiliates'
        },   
        {
          key: 'recommendations',
          title: 'המלצות',
          link: '/recommendations'
        },
        {
          key: "members",
          title: 'משתמשים',
          link: '/members'
        },
        {
          key: "professionals",
          title: 'רופאים / מטפלים',
          link: '/professionals'
        },
      ],
    },
    {
      type: "box",
      title: "שותפים",
      icon: "k-i-calendar",    
      color: "#ad71e0",
      style: {
        minWidth: "60%"
      },
      data: [
        {
        key: "affiliates_table",
        type: 'table',
        headers: [
         'שם פרטי',
         'שם משפחה',
       //  'פלאפון',
          'מחיר להרשמה',
          'מ. ממליצים',
          'מ. סיימו להמליץ',
          'מ. המלצות',
        ],
        clickable: true,
        routeTo: '/affiliates/',
        columns: [
          {
            key: 'first_name',
          },
          {
            key: 'last_name'
          },
         /* {
            key : 'mobile',
          },*/
          {
            key : 'price_for_member',
          },
          {
            key : 'aff_members'
          },
          {
            key : 'aff_finish',
          },
          {
            key : 'aff_count'
          },
        ]
      }, 
      ],
    },    
    {
      type: "box",
      title: "קטגוריות",
      icon: "k-i-calendar",    
      style: {
        minWidth: "30%"
      },
      color: "#ad71e0",
      data: [
        {
          key: "category_table",
          type: 'table',
          clickable: true,
          routeTo: '/professionals#sort=-rec_count&category_id=',
          headers: [
            'קטגוריה',
            'מ. רופאים',
            'מ. המלצות',
            'מ. רופאים מומלצים'
          ],
          columns: [
            {
              key: 'name',
            },
            {
              key: 'porf_count'
            },
            {
              key : 'rec_count',
            },
            {
              key : 'prof_rec_count',
            },
          ]
        }, 
  
      ]
    },
     
    {
      type: "box",
      title: "משתמשים AFF",
      icon: "k-i-calendar",    
      style: {
        minWidth: "30%"
      },
      color: "#ad71e0",
      data: [
        {
          key: "member_aff_table",
          type: 'table',
          clickable: true,
          routeTo: '/professionals/',
          headers: [
           'שם מלא',
           'רופא/מטפל?',
           'מ. המלצות שותף',
          ],
          columns: [
            {
              key: 'name',
            },
            {
              key: 'prof'
            },
            {
              key : 'aff_count',
            },
          ]
        }, 
  
      ]
    },
      
    
  ];

  const ClickRow = (item, id) => {
    if (!item.clickable || !id) {
      return;
    }
    negative(item.routeTo + id)
  }


  const renderTable = (item) => {
    const data = home.data.dashbaord_data.data[item.key]

    if (!data || !data.length) {
      return (<h2>{item.nodata || 'לא קיימים נתונים'}</h2>)
    }
   
    return (
      <>
        {item?.headers ?
          <div className="list-row header">
            {item.headers.map(header => (
              <div className="list-col header" style={header.style}>
                {header.title || header}
              </div>
            ))}
          </div>
          : null}
        
        
        {data?.map(row => (
          <div className={'list-row ' + (item.clickable && row.id ? 'clickable' : '')} onClick={() => ClickRow(item, row.id)}>
            {item.columns.map(col => (
              <div className="list-col" style={col.style}>
                {row[col.key]}
              </div>
            ))}
          </div>
        ))}
      </>
    )

  }

  const renderContent = (item, i) => {
     let colors = {
         0: 'rgba(4, 35, 125, 0.85)',
         1: 'rgba(179, 204, 204, 0.85)',
         2: 'rgba(77, 77, 255, 0.85)',
        };
       
    
    switch (item.type) {
      case "box":
        return (
          <div className="box_item_wrap" >
            {item.data?.map((el, i) => {
              switch (el.type) {
                case 'table':
                  return renderTable(el)
                default:
                  return (
                    <div className={"box_item " + (el.link ? 'clickable' : '')} key={el.key} onClick={() => ClickRow({routeTo : el.link , clickable: !!el.link}, ' ')}>
                      <div className="box_item_key">{el.title}</div>
                      <div className="box_item_value" style={{ color: el.color }}>{home.data.dashbaord_data?.data[el.key]}</div>
                   
                  </div>
                )
                  break
            
              }
            }
            )}
          </div>
        );
      case 'column':
        
          return (
              <div className="pie_item_wrap">
               <Chart>
            <ChartTitle />
            <ChartLegend position="top" orientation="horizontal" />
                
            <ChartCategoryAxis>
              <ChartCategoryAxisItem categories={home.data.dashbaord_data.data[item.key]?.categories} startAngle={45} />
            </ChartCategoryAxis>
            <ChartSeries>
              {home.data.dashbaord_data.data[item.key]?.series.map((item, idx) => (
                <ChartSeriesItem
                  key={idx}
                  type="column"
                  tooltip={{
                    visible: true,
                  }}
                  color={colors[idx] || undefined}
                  
                  data={item.data}
                  name={item.name}
                />
              ))}
            </ChartSeries>
          </Chart>
              </div>
            );
      case "pie":
        
        let labelContent = (props) => {       
          return Number(props.dataItem.value).toLocaleString();
        };
            return (
              <div className="pie_item_wrap">
                <Chart>
                <ChartTitle />
                <ChartLegend position="bottom" />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                      data={home.data.dashbaord_data.data[item.key].map((item, idx) => {
                        return { ...item, color: colors[idx] || undefined }
                      })}
                      field="value"
                      colorField="color"
                      categoryField="key"
                      labels={{
                        visible: true,
                        content: labelContent,//item.value,
                      }}
                  />
                </ChartSeries>
              </Chart>
              </div>
            );
    }
  };

  const Filters = () => {

    
    const Change = (filter, value, sendToServer = true) => {
    if (!sendToServer) {
      return
    }
    let params = {
      name: 'home'
    }
    params[filter.name] = value;

    setParams(params);
    dispatch(getIndex(params))
    }
    
    return (
      
      <div className="filter-work-point"> 
        {/*user?.Role < 3 &&
          <BaseInput data={{ type: 'select', value: params.branch || null, options: home.data.branchs || [], placeholder: 'בחר ', name: 'branch' }} onChange={Change} />
    */}
        
        
      
      </div>
    )
  }


  return (
    <section className="content">
    
       {home && <Title subTitle={home?.data?.dashbaord_data?.data?.created} title={'מבט על ' + getTitle()} />}
    
       <Filters/>
      <div className="cards_flex">
        {home.data?.dashbaord_data?.data ? list?.filter(el => !el.roles || el.roles.includes(user?.Role)).map((item, i) => (
          <Card key={i} className="home_card" style={item.style}>
            <CardTitle
              style={{
               // color: item.color,
              }}
              className="title_card">
              <span className="title_text">
                <span className="title_value"> {item.name ? home.data.dashbaord_data?.data[item.name] : item.title}</span>
                {item.name ? (<><br/> {item.title}</>) : null}
              </span>
            </CardTitle>
            <CardBody>{renderContent(item, i)}</CardBody>
          </Card>
        )): 'לא קיימים נתונים'}
      </div>
    </section>
  );
};

export default Home;
