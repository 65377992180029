import { tab } from '@testing-library/user-event/dist/tab'
import {
    
  LOOKUP,
  INDEX,
  DELETE,
  UPDATE,
  UPDATE_FIELDS,
  UPDATE_ROW,
  CREATE,
  VIEW,
  VIEW_CHILD,
  LOG_DATA,
  ADD_LOG_ITEM,
  VALIDATIONֹֹֹ_ERROR,  
  DELETE_LOG_ITEM,
  UPDATE_LOG_ITEM,
  START_LOG,
  AUTO_COMPLATE,
  ENTER_EDIT,
  CREATE_INLINE,
  SHOW_MORE_LOG,
  UPDATE_CURRENT_ITEM_FIELDS
 } from '../actions/types'
  
  const initialState = {
    currentItem: null,
    currentItemChild: null,
    currentLog: null,
    currentSuggestion: [],
    startLog : null
  }
  
  const baseDataReducer = (state = initialState, action) => {
    const {type, payload} = action
       
    
    switch (type) {
      case LOOKUP:
        const title = payload.model
        if(!state[title]) {
          state[title] = {}
        } 
        state[title].LOOKUP = { ...payload }       
          return state 
      case VIEW:
        state.currentItem = payload
        if(state.currentItem) {
          state.currentItem.editFeildsData = {...state.currentItem.feildsData}
          state.currentItem.errorValidation = []
        }
        return state
      case VIEW_CHILD:
        state.currentItemChild = payload
        if(state.currentItemChild) {
          state.currentItemChild.editFeildsData = {...payload.feildsData}
          state.currentItemChild.errorValidation = []
        }
        return state;
      case UPDATE_FIELDS:
        if (payload.isParent && state.currentItem) {
          state.currentItem.editFeildsData = payload.data
        } else if( state.currentItemChild) {
          state.currentItemChild.editFeildsData = payload.data

        }
        return state
      case UPDATE_CURRENT_ITEM_FIELDS:
        
        if (payload.tab && payload.section) {
          state.currentItem.tabs[payload.tab].sections[payload.section] = payload.data
        } else if(state.currentItem.fields) {
          state.currentItem.fields = payload.data
        }
        return state
      case VALIDATIONֹֹֹ_ERROR:
        if(!state.currentItem) {
          state.currentItem  = {errorValidation : []}
        }
        state.currentItem.errorValidation = payload
        return state
      case UPDATE:       
        state.currentItem.errorValidation = []
        if(payload.isParent) {
          state.currentItem.feildsData = payload.data
          state.currentItem.editFeildsData = payload.data
        } else {
          state.currentItemChild.feildsData = payload.data
          state.currentItemChild.editFeildsData = payload.data
          
        }
        /*if(state[payload.model]) {
          state[payload.model]['data'].rows = state[payload.model]['data'].rows.map((row) => {
            if(row.id == payload.data.id) {
              return payload.data
            }
            return row
          })
        }*/
        
        return state
      case UPDATE_ROW:
        if (state.currentItem) {
          state.currentItem.errorValidation = []
        }
        if (state[payload.model]?.data?.rows) {

            state[payload.model].data.rows = state[payload.model]?.data?.rows.map((row) => {
           
              if (row.id == payload.data.id && payload.field && payload.data[payload.field] !== undefined) {
                const fieldObj = {}
                fieldObj[payload.field] = payload.data[payload.field] 
                return { ...row, ...fieldObj }
              }
              
              return row
            })
          
        }
        return state
      case CREATE:
        if (state.currentItem) {
          state.currentItem.errorValidation = []
        }
        if(payload.isParent) {
          state.currentItem.feildsData = payload.data
          state.currentItem.editFeildsData = payload.data
        } else {
          state.currentItemChild.feildsData = payload.data
          state.currentItemChild.editFeildsData = payload.data
          
        }

        /*if (state[payload.model] && state[payload.model]['data']) {
          state[payload.model]['data'].rows.unshift(payload.data)
          state[payload.model]['data'].count++
        }*/
        return state
      case INDEX:
    
        if(!state[payload.model]) {
          state[payload.model] = { 'data': {}}  
        }
        state[payload.model]['data'] = payload.data
        if(payload.data.LOOKUP && ! state[payload.model]['LOOKUP']) {
          state[payload.model]['LOOKUP'] = payload.data.LOOKUP
        }

        //if (payload.sortBy || payload.page) {
          if (!state[payload.model]['LOOKUP']) {
            state[payload.model]['LOOKUP'] = {
              gridSettings: {
                               
              }
            }
          }
          if (!state[payload.model]['LOOKUP'].gridSettings[payload.model]) {
               state[payload.model]['LOOKUP'].gridSettings[payload.model] = { }
          }
         
          state[payload.model]['LOOKUP'].gridSettings[payload.model].sort = payload.sortBy || '-id';
          state[payload.model]['LOOKUP'].gridSettings[payload.model].page = payload.page || 1;
          state[payload.model]['LOOKUP'].gridSettings[payload.model].take = payload.take || 100;
          state[payload.model]['LOOKUP'].gridSettings[payload.model].filters = payload.filters || {};
          
          
       
        //}
         
        return state
      
      case ENTER_EDIT:
        if(state[payload.model]) {
          state[payload.model].data.rows = state[payload.model].data.rows.map(item =>
            item.id === payload.id ? { ...item, inEdit: payload.inEdit } : item
            )
        }
        //state.currentItem.feildsData =  payload.data || {}
        //state.currentItem.editFeildsData = payload.data || {}
        return state;
      case DELETE:
        payload.id.toString().split(',').forEach(id => {
          const index = state[payload.model]['data'].rows.findIndex(row => row.id == id)
          state[payload.model]['data'].rows.splice(index, 1)
        })
        return state;
      case CREATE_INLINE:
        return state
      default:
        return state
      case LOG_DATA:
        return {
          ...state,
          startLog: '',
          currentLog: payload
        }
      case SHOW_MORE_LOG:
        const newPayload = {
          ...payload,
          rows: payload.id = state.currentLog.id && payload.model == state.currentLog.model ? [...state.currentLog.rows, ...payload.rows] : payload.rows,
          count: payload.count
          
          };
        return {
          ...state,
          startLog: '',
          currentLog: newPayload
        }
      case ADD_LOG_ITEM: 
        state.startLog = ''
        if(!state.currentLog) {
          state.currentLog = {
            rows : [],
            count: 0,
          }
        }

        state.currentLog.rows.unshift(payload)
        state.currentLog.count++;
        return state
      case DELETE_LOG_ITEM:
        if(state.currentLog) {
          state.currentLog.rows.splice(state.currentLog.rows.findIndex(r => r.id == payload.id),1)
          state.currentLog.count--;
        }
        return state
      case UPDATE_LOG_ITEM:
        if(!state.currentLog) {
          return state
        }
        state.currentLog.rows = state.currentLog.rows.map(r => {
          if(r.id == payload.id) {
            return payload;
          }
          return r
        })
        return state
      case START_LOG:
        return {
          ...state, 
          startLog: payload
        }
      case AUTO_COMPLATE:
        return {
          ...state,
          currentSuggestion: payload
        }        
    }
  }
  
  export default baseDataReducer
  