import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./DeleteModal.css";
import { Button } from "@progress/kendo-react-buttons";

const DeleteModal = (props) => {
  return (
    <Dialog title={props.title} onClose={props.close} dir="rtl">
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        {props.text || 'האם להמשיך במחיקה?'}
      </p>
<div className="actions_delete">
        <Button fillMode={"flat"} onClick={props.close} style={{ margin: '0 5px'}}>
          ביטול
        </Button>
        <Button type="" className="delete_btn" themeColor={"warning"} onClick={props.delete}>
          {props.delText || 'מחיקה' }
        </Button>
   </div>
    </Dialog>
  );
};

export default DeleteModal;
