// Async
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START'
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH'
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR'
export const ASYNC_ACTION_CLEAN_ERROR = 'ASYNC_ACTION_CLEAN_ERROR'

// User
export const OTP='OTP'
export const RESTART_OTP='RESTART_OTP'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const RESET_FORGOT_FLOW = 'RESET_FORGOT_FLOW'
export const CLEAN_REGISTRATION_SUCCESS = 'CLEAN_REGISTRATION_SUCCESS'
export const SET_AS_AUTHENTICATED = 'SET_AS_AUTHENTICATED'
export const SET_PROFILE_ITEMS = 'SET_PROFILE_ITEMS'
export const USER_DATA = 'USER_DATA'

// base
export const LOOKUP = 'LOOKUP'
export const CREATE = 'CREATE'
export const CREATE_INLINE = 'CREATE_INLINE'
export const INDEX = 'INDEX'
export const UPDATE = 'UPDATE'
export const DELETE =  'DELETE'
export const VIEW = 'VIEW'
export const VIEW_CHILD = 'VIEW_CHILD'
export const UPDATE_FIELDS = 'UPDATE_FIELDS'
export const VALIDATIONֹֹֹ_ERROR = 'VALIDATIONֹֹֹ_ERROR'
export const AUTO_COMPLATE = 'AUTO_COMPLATE'
export const ENTER_EDIT = 'ENTER_EDIT'
export const UPDATE_ROW = 'UPDATE_ROW'
export const UPDATE_CURRENT_ITEM_FIELDS = 'UPDATE_CURRENT_ITEM_FIELDS'
export const UPDATE_MULTI_ITEMS = 'UPDATE_MULTI_ITEMS';
// log

export const LOG_DATA = 'LOG_DATA'
export const ADD_LOG_ITEM = 'ADD_LOG_ITEM'

export const DELETE_LOG_ITEM = 'DELETE_LOG_ITEM'
export const UPDATE_LOG_ITEM = 'UPDATE_LOG_ITEM'
export const START_LOG = 'START_LOG'
export const SHOW_MORE_LOG = 'SHOW_MORE_LOG'



// Navbar
export const SET_NAVBAR_CURRENT_ITEM = 'SET_NAVBAR_CURRENT_ITEM'
export const SET_EXPPANDED_NAV = 'SET_EXPPANDED_NAV'
export const SET_ITEMS = 'SET_ITEMS'

// Modal
export const OPEN_MODAL = 'OPEN_LOGIN_MODAL'
export const CLOSE_MODAL = 'CLOSE_LOGIN_MODAL'

// Accessibility
export const SET_CONTRAST_ACCESSIBILITY_CLASS = 'SET_CONTRAST_ACCESSIBILITY_CLASS'
export const SET_FONT_ACCESSIBILITY_CLASS = 'SET_FONT_ACCESSIBILITY_CLASS'
export const SET_NAVIGATION_ACCESSIBILITY_CLASS = 'SET_NAVIGATION_ACCESSIBILITY_CLASS'


// NOTIFICATIONS
export const FRESH_NOTIFICATIONS = 'FRESH_NOTIFICATIONS';